export default [
  {
    from: 'v1.7.0',
    to: 'v1.8.0',
    new: [
      'src/assets/img/file-manager/1.png',
      'src/assets/img/file-manager/11.png',
      'src/assets/img/file-manager/12.png',
      'src/assets/img/file-manager/13.png',
      'src/assets/img/file-manager/15.png',
      'src/assets/img/file-manager/16.png',
      'src/assets/img/file-manager/2.png',
      'src/assets/img/file-manager/6.png',
      'src/assets/img/file-manager/7.png',
      'src/assets/img/file-manager/9.png',
      'src/assets/img/generic/67.pdf',
      'src/assets/img/spot-illustrations/45.png',
      'src/assets/img/spot-illustrations/46.png',
      'src/assets/img/spot-illustrations/dark_45.png',
      'src/assets/img/spot-illustrations/dark_46.png',
      'src/assets/scss/theme/_file-manager.scss',
      'src/assets/scss/theme/_treeview.scss',
      'src/assets/video/1.mp4',
      'src/assets/video/1.png',
      'src/assets/video/2.mp4',
      'src/assets/video/2.png',
      'src/assets/video/3.mp4',
      'src/assets/video/3.png',
      'src/assets/video/4.mp4',
      'src/assets/video/4.png',
      'src/components/modules/file-manager/FileManagerContent.tsx',
      'src/components/modules/file-manager/FilesDropdown.tsx',
      'src/components/modules/file-manager/FilesHeader.tsx',
      'src/components/modules/file-manager/RecentFilesCard.tsx',
      'src/components/modules/file-manager/myfile-contents/FileBox.tsx',
      'src/components/modules/file-manager/myfile-contents/FileDetails.tsx',
      'src/components/modules/file-manager/myfile-contents/GridGroupView.tsx',
      'src/components/modules/file-manager/myfile-contents/MyFilesActionBar.tsx',
      'src/components/modules/file-manager/myfile-contents/MyFilesHeader.tsx',
      'src/components/modules/file-manager/myfile-contents/RenderFileIcon.tsx',
      'src/components/modules/file-manager/sidebar/Sidebar.tsx',
      'src/components/modules/file-manager/sidebar/StorageDetails.tsx',
      'src/components/modules/file-manager/sidebar/TreeView.tsx',
      'src/components/tables/FileManagerTableWrapper.tsx',
      'src/components/tables/ListViewGroupTable.tsx',
      'src/components/timelines/FileManagerTimeline.tsx',
      'src/data/file-manager.ts',
      'src/data/treeview.ts',
      'src/layouts/FileManagerLayout.tsx',
      'src/pages/apps/file-manager/GridView.tsx',
      'src/pages/apps/file-manager/ListView.tsx',
      'src/providers/FileManagerProvider.tsx'
    ],
    update: [
      'package.json',
      'src/Routes.tsx',
      'src/assets/scss/theme/_ecommerce.scss',
      'src/assets/scss/theme/_mixed.scss',
      'src/assets/scss/theme/_offcanvas.scss',
      'src/assets/scss/theme/_theme.scss',
      'src/assets/scss/theme/_variables.scss',
      'src/components/base/LightBox.tsx',
      'src/components/base/PhoenixOffcanvas.tsx',
      'src/components/modules/faq/CategoryOffcanvas.tsx',
      'src/components/modules/travel-agency/hotel/search-room/RoomFilterSearch.tsx',
      'src/components/timelines/BasicTimeline.tsx',
      'src/hooks/useLightbox.tsx',
      'src/providers/BreakpointsProvider.tsx',
      'src/providers/FaqTabProvider.tsx',
      'src/sitemap.tsx'
    ]
  },
  {
    from: 'v1.6.0',
    to: 'v1.7.0',
    new: [
      'src/assets/img/bg/46.png',
      'src/assets/img/bg/47.png',
      'src/assets/img/bg/bg-41.svg',
      'src/assets/img/trip/1.png',
      'src/assets/img/trip/2.png',
      'src/assets/img/trip/3.png',
      'src/assets/img/trip/4.png',
      'src/assets/img/trip/5.png',
      'src/assets/img/trip/6.png',
      'src/assets/img/trip/7.png',
      'src/assets/img/trip/8.png',
      'src/assets/img/trip/9.png',
      'src/assets/img/trip/11.png',
      'src/assets/img/trip/12.png',
      'src/assets/img/trip/13.png',
      'src/assets/img/trip/14.png',
      'src/assets/img/trip/15.png',
      'src/assets/img/trip/16.png',
      'src/assets/img/trip/17.png',
      'src/assets/img/trip/18.png',
      'src/assets/img/trip/19.png',
      'src/assets/img/trip/20.png',
      'src/assets/img/trip/21.png',
      'src/assets/img/trip/22.png',
      'src/assets/img/trip/23.png',
      'src/assets/img/trip/24.png',
      'src/assets/img/trip/25.png',
      'src/assets/img/trip/25_large.jpg',
      'src/assets/img/trip/26.png',
      'src/assets/img/trip/26_large.jpg',
      'src/assets/img/trip/27.png',
      'src/assets/img/trip/27_large.jpg',
      'src/assets/img/trip/28.png',
      'src/assets/img/trip/28_large.jpg',
      'src/assets/img/trip/29.png',
      'src/assets/img/trip/29_large.jpg',
      'src/assets/img/trip/30.png',
      'src/assets/img/trip/30_large.jpg',
      'src/assets/img/trip/31.png',
      'src/assets/img/trip/31_large.jpg',
      'src/assets/img/trip/32.png',
      'src/assets/img/trip/32_large.jpg',
      'src/assets/img/trip/33.png',
      'src/assets/img/trip/33_large.jpg',
      'src/components/base/MapBoxCluster',
      'src/components/cards/TripDetailsModalPricingPlanCard',
      'src/components/cards/TripSummaryCard',
      'src/components/charts/echarts/TripDetailsReviewChart',
      'src/components/common/GenerateStar',
      'src/components/common/InputGroupCounter',
      'src/components/common/TextTruncate',
      'src/components/forms/TripCheckoutForms',
      'src/components/image-gallery/TripDetailsReviewTabCommentUpload',
      'src/components/modals/TripDetailsAvailabilityModal',
      'src/components/modules/travel-agency/trip/TripShowcaseItem',
      'src/components/modules/travel-agency/trip/checkout/TripCheckoutFormDatePicker',
      'src/components/modules/travel-agency/trip/checkout/TripCheckoutFormTimePicker',
      'src/components/modules/travel-agency/trip/homepage/TripCommonCTASection',
      'src/components/modules/travel-agency/trip/homepage/TripHomepageFilterOffcanvas',
      'src/components/modules/travel-agency/trip/homepage/TripHomepageFilterOffcanvasContent',
      'src/components/modules/travel-agency/trip/homepage/TripHomepageHeroBanner',
      'src/components/modules/travel-agency/trip/homepage/TripHomepageTripList<code>',
      'src/components/modules/travel-agency/trip/trip-details/TripDetailsAlbum',
      'src/components/modules/travel-agency/trip/trip-details/TripDetailsGallery',
      'src/components/modules/travel-agency/trip/trip-details/TripDetailsOverview',
      'src/components/modules/travel-agency/trip/trip-details/TripDetailsTab',
      'src/components/modules/travel-agency/trip/trip-details/TripDetailsTabDetailsContent',
      'src/components/modules/travel-agency/trip/tip-details/TripDetailsTabReviewContent',
      'src/components/modules/travel-agency/trip/details-tab/TripDetailsSummary',
      'src/components/modules/travel-agency/trip/details-tab/TripDetailsTabDetailsAccessibility',
      'src/components/modules/travel-agency/trip/details-tab/TripDetailsTabDetailsDeparture',
      'src/components/modules/travel-agency/trip/details-tab/TripDetailsTabDetailsExpectation',
      'src/components/modules/travel-agency/trip/details-tab/TripDetailsTabDetailsIncluded',
      'src/components/modules/travel-agency/trip/details-tab/TripDetailsTabDetailsPolicy',
      'src/components/modules/travel-agency/trip/review-tab/TripDetailsTabReviewAllReviews',
      'src/components/modules/travel-agency/trip/review-tab/TripDetailsTabReviewCommentCard',
      'src/components/sliders/TripDetailsMostHighlights',
      'src/data/travel-agency/customer/trip.ts',
      'src/layouts/TripLayout',
      'src/pages/apps/trip/Checkout',
      'src/pages/apps/trip/Homepage',
      'src/pages/apps/trip/TripDetails'
    ],
    update: [
      'package.json',
      'package.lock.json',
      'public/tinymce/CHANGELOG.md',
      'src/Routes.tsx',
      'src/assets/scss/theme/_crm.scss',
      'src/assets/scss/theme/_gantt_chart.scss',
      'src/assets/scss/theme/_hover.scss',
      'src/assets/scss/theme/_kanban.scss',
      'src/assets/scss/theme/_mixed.scss',
      'src/assets/scss/theme/_navbar-vertical.scss',
      'src/assets/scss/theme/_navbar.scss',
      'src/assets/scss/theme/_travel-agency.scss',
      'src/components/cards/DealCard.tsx',
      'src/components/charts/echarts/EcomPayingVsNonPayingChart.tsx',
      'src/components/chart/EcomTopCouponsChart.tsx',
      'src/components/common/CollapsibleContainer.tsx',
      'src/components/common/NotificationItem.tsx',
      'src/components/common/ProductCard.tsx',
      'src/components/footers/Footer.tsx',
      'src/components/image-gallery/HotelDetailsGallery.tsx',
      'src/components/list-items/ProductReview.tsx',
      'src/components/modals/ReviewModal.tsx',
      'src/components/modals/DealsAddStageModal.tsx',
      'src/components/modals/KanbanAddListModal.tsx',
      'src/components/modules/crm/deals/DealColumn.tsx',
      'src/components/modules/kanban/KanbanList.tsx',
      'src/components/modules/kanban/KanbanListItemCard.tsx',
      'src/components/modules/kanban/create-board/ColumnForm.tsx',
      'src/components/modules/kanban/create-board/CreateBoardWizardForm.tsx',
      'src/components/modules/project-management/dashboard/IssueDiscovered.tsx',
      'src/components/modules/travel-agency/flight/homepage/FilterFormFlightBaggage.tsx',
      'src/components/modules/travel-agency/hotel/HomepageFilterOffcanvasContent.tsx',
      'src/components/modules/travel-agency/hotel/HotelActions.tsx',
      'src/components/modules/travel-agency/hotel-details/HotelDetailsAvailability.tsx',
      'src/components/modules/showcase/ImportantApplications.tsx',
      'src/components/navbars/nav-items/NotificationDropdownMenu.tsx',
      'src/components/navbars/travel-agency/NavbarHome.tsx',
      'src/components/navbars/travel-agency/NavbarMain.tsx',
      'src/components/navbars/travel-agency/ResizeableNavbar.tsx to src/components/navbars/travel-agency/ResizableNavbar.tsx',
      'src/components/tables/ProjectDashboardTable.tsx',
      'src/data/migrations.ts',
      'src/data/showcase.ts',
      'src/data/notifications.ts',
      'src/data/kanban.ts',
      'src/data/crm/deals.ts',
      'src/data/travel-agency/customer/resizeableNav.ts to src/data/travel-agency/customer/resizableNav.ts',
      'src/helpers/echart-utils.ts',
      'src/pages/apps/crm/Deals.tsx',
      'src/pages/apps/e-commerce/admin/OrderDetails.tsx',
      'src/pages/apps/kanban/Kanban.tsx',
      'src/pages/apps/travel-agency/Flight/Booking.tsx to src/pages/apps/travel-agency/flight/Booking.tsx',
      'src/pages/apps/travel-agency/Flight/HomePage.tsx to src/pages/apps/travel-agency/flight/Homepage.tsx',
      'src/pages/apps/travel-agency/Flight/Payment.tsx to src/pages/apps/travel-agency/flight/Payment.tsx',
      'src/pages/apps/travel-agency/hotel/customer/HotelCheckout.tsx',
      'src/pages/apps/travel-agency/hotel/customer/HotelCompare.tsx',
      'src/pages/apps/travel-agency/hotel/customer/HotelDetails.tsx',
      'src/pages/apps/travel-agency/hotel/customer/HotelGallery.tsx',
      'src/pages/apps/travel-agency/hotel/customer/HotelPayment.tsx',
      'src/pages/apps/travel-agency/homepage/Homepage.tsx',
      'src/pages/modules/components/DraggableExample.tsx',
      'src/providers/CrmDealsProvider.tsx',
      'src/reducers/KanbanReducer.ts',
      'src/sitemap.tsx'
    ],
    delete: ['src/components/base/PhoenixDroppable.tsx']
  },
  {
    from: 'v1.5.0',
    to: 'v1.6.0',
    new: [
      'public/css/user.min.css.map',
      'src/assets/img/bg/44.png',
      'src/assets/img/bg/45.png',
      'src/assets/img/brand/emirates-airline.png',
      'src/assets/img/brand/japan-airline.png',
      'src/assets/img/brand/qatar-airline.png',
      'src/assets/img/icons/long-arrow-down.svg',
      'src/assets/img/icons/long-arrow.svg',
      'src/assets/img/spot-illustrations/44-dark.png',
      'src/assets/img/spot-illustrations/44.png',
      'src/components/cards/CuponCard.tsx',
      'src/components/modals/FlightFilterModal.tsx',
      'src/components/modals/FlightPromoModal.tsx',
      'src/components/modules/travel-agency/flight/booking/FlightDetails.tsx',
      'src/components/modules/travel-agency/flight/booking/FlightInfo.tsx',
      'src/components/modules/travel-agency/flight/booking/PaymentSummary.tsx',
      'src/components/modules/travel-agency/flight/booking/TravelerDetails.tsx',
      'src/components/modules/travel-agency/flight/homepage/FilterFormFlightAircraft.tsx',
      'src/components/modules/travel-agency/flight/homepage/FilterFormFlightAirports.tsx',
      'src/components/modules/travel-agency/flight/homepage/FilterFormFlightBaggage.tsx',
      'src/components/modules/travel-agency/flight/homepage/FilterFormFlightSchedule.tsx',
      'src/components/modules/travel-agency/flight/homepage/FlightEditForm.tsx',
      'src/components/modules/travel-agency/flight/homepage/FlightFilterFormContent.tsx',
      'src/components/modules/travel-agency/flight/homepage/FlightFooter.tsx',
      'src/components/modules/travel-agency/flight/homepage/FlightListing.tsx',
      'src/components/modules/travel-agency/flight/homepage/FlightPreviewForm.tsx',
      'src/components/modules/travel-agency/flight/homepage/FlightSearch.tsx',
      'src/components/modules/travel-agency/flight/homepage/FlightSearchForm.tsx',
      'src/components/modules/travel-agency/flight/homepage/FligthListHead.tsx',
      'src/components/modules/travel-agency/hotel/HomepageFilterOffcanvas.tsx',
      'src/components/modules/travel-agency/hotel/HomepageFilterOffcanvasContent.tsx',
      'src/components/navbars/travel-agency/ResizableNavbar.tsx',
      'src/data/travel-agency/ResizableNav.ts',
      'src/data/travel-agency/customer/flight.ts',
      'src/pages/apps/travel-agency/Flight/Booking.tsx',
      'src/pages/apps/travel-agency/Flight/BottomBar.tsx',
      'src/pages/apps/travel-agency/Flight/HomePage.tsx',
      'src/pages/apps/travel-agency/Flight/Payment.tsx',
      'src/pages/apps/travel-agency/Flight/PaymentInfo.tsx',
      'src/pages/apps/travel-agency/landing/TopNav copy.tsx',
      'src/pages/apps/travel-agency/landing/TravelAgencyFooter.tsx',
      'src/pages/apps/travel-agency/payment/CountdownDisplay.tsx',
      'src/pages/apps/travel-agency/payment/FlightBookingWizard.tsx'
    ],
    update: [
      'src/assets/scss/theme/_buttons.scss',
      'src/assets/scss/theme/_chat-widget.scss',
      'src/assets/scss/theme/_mixed.scss',
      'src/assets/scss/theme/_modal.scss',
      'src/assets/scss/theme/_navbar-top.scss',
      'src/assets/scss/theme/_navbar.scss',
      'src/assets/scss/theme/_travel-agency.scss',
      'src/assets/scss/theme/plugins/_react-dropzone.scss',
      'src/assets/scss/theme/plugins/_swiper.scss',
      'src/assets/scss/theme/plugins/_tinymce.scss',
      'src/components/base/Dropzone.tsx',
      'src/components/base/MapBox.tsx',
      'src/components/cards/HolidaysCard.tsx',
      'src/components/cards/HotelCard.tsx',
      'src/components/cards/HotelDetailsSummaryCard.tsx',
      'src/components/charts/e-charts/GrossProfitChart.tsx',
      'src/components/common/CollapsibleContainer.tsx',
      'src/components/common/ImageAttachmentPreview.tsx',
      'src/components/common/PageBreadcrumb.tsx',
      'src/components/common/ProductCard.tsx',
      'src/components/common/chat-widget/ChatWidget.tsx',
      'src/components/footers/Footer.tsx',
      'src/components/footers/TravelFooter.tsx',
      'src/components/forms/PaymentMethodForm.tsx',
      'src/components/image-gallery/HotelDetailsGallery.tsx',
      'src/components/modals/HotelChangeRoomModal.tsx',
      'src/components/modules/crm/deals-details/DealDetailsInfo.tsx',
      'src/components/modules/kanban/create-board/ColumnForm.tsx',
      'src/components/modules/landing/default/address/HelpCenter.tsx',
      'src/components/modules/travel-agency/dashboard/FinancialActivities.tsx',
      'src/components/modules/travel-agency/dashboard/FlightMap.tsx',
      'src/components/modules/travel-agency/dashboard/FlightsHeader.tsx',
      'src/components/navbars/navbar-horizontal/NavbarTopNav.tsx',
      'src/components/navbars/navbar-vertical/NavbarVerticalMenu.tsx',
      'src/components/navbars/travel-agency/NavbarHome.tsx',
      'src/components/navbars/travel-agency/NavbarMain.tsx',
      'src/components/navs/IsotopeNav.tsx',
      'src/components/tables/HotelCompareAmenityRow.tsx',
      'src/components/tables/HotelCompareRatingRow.tsx',
      'src/components/tables/TravelFlightTable.tsx',
      'src/components/wizard/WizardNavItem.tsx',
      'src/data/changelog.ts',
      'src/data/project-management/stats.ts',
      'src/data/travel-agency/customer/hotel.ts',
      'src/data/travel-agency/customer/hotelCompare.ts',
      'src/data/travel-agency/customer/hotelDetails.ts',
      'src/helpers/echart-utils.ts',
      'src/helpers/utils.ts',
      'src/hooks/useAdvanceTable.tsx',
      'src/layouts/TravelAgencyLayout.tsx',
      'src/layouts/TravelLandingLayout.tsx',
      'src/pages/apps/e-commerce/admin/CustomerDetails.tsx',
      'src/pages/apps/travel-agency/hotel/admin/AddProperty.tsx',
      'src/pages/apps/travel-agency/hotel/admin/AddRoom.tsx',
      'src/pages/apps/travel-agency/hotel/admin/SearchRoom.tsx',
      'src/pages/apps/travel-agency/hotel/customer/HotelCheckout.tsx',
      'src/pages/apps/travel-agency/hotel/customer/HotelCompare.tsx',
      'src/pages/apps/travel-agency/hotel/customer/HotelDetails.tsx',
      'src/pages/apps/travel-agency/hotel/customer/HotelGallery.tsx',
      'src/pages/apps/travel-agency/hotel/customer/HotelPayment.tsx',
      'src/pages/apps/travel-agency/hotel/customer/homepage/Homepage.tsx',
      'src/pages/apps/travel-agency/landing/TopNav.tsx',
      'src/pages/dashboard/TravelAgency.tsx',
      'src/pages/modules/tables/AdvanceTableExample.tsx',
      'src/sitemap.tsx',
      'src/components/modules/travel-agency/dashboard/hotel/HotelActions.tsx to src/components/modules/travel-agency/hotel/HotelActions.tsx',
      'src/components/modules/travel-agency/dashboard/hotel/HotelGalleryImages.tsx to src/components/modules/travel-agency/hotel/HotelGalleryImages.tsx',
      'src/components/modules/travel-agency/dashboard/hotel/HotelGalleryItem.tsx to src/components/modules/travel-agency/hotel/HotelGalleryItem.tsx',
      'src/components/modules/travel-agency/dashboard/hotel/add-proterty/AddPhotos.tsx to src/components/modules/travel-agency/hotel/add-proterty/AddPhotos.tsx',
      'src/components/modules/travel-agency/dashboard/hotel/add-proterty/BasicInformationForm.tsx to src/components/modules/travel-agency/hotel/add-proterty/BasicInformationForm.tsx',
      'src/components/modules/travel-agency/dashboard/hotel/add-proterty/FinanceForm.tsx to src/components/modules/travel-agency/hotel/add-proterty/FinanceForm.tsx',
      'src/components/modules/travel-agency/dashboard/hotel/add-proterty/GeneralAmenitiesForm.tsx to src/components/modules/travel-agency/hotel/add-proterty/GeneralAmenitiesForm.tsx',
      'src/components/modules/travel-agency/dashboard/hotel/add-proterty/LocationForm.tsx to src/components/modules/travel-agency/hotel/add-proterty/LocationForm.tsx',
      'src/components/modules/travel-agency/dashboard/hotel/add-proterty/PoliciesForm.tsx to src/components/modules/travel-agency/hotel/add-proterty/PoliciesForm.tsx',
      'src/components/modules/travel-agency/dashboard/hotel/add-proterty/Preview.tsx to src/components/modules/travel-agency/hotel/add-proterty/Preview.tsx',
      'src/components/modules/travel-agency/dashboard/hotel/add-proterty/SummaryTable.tsx to src/components/modules/travel-agency/hotel/add-proterty/SummaryTable.tsx',
      'src/components/modules/travel-agency/dashboard/hotel/add-room/Amenities.tsx to src/components/modules/travel-agency/hotel/add-room/Amenities.tsx',
      'src/components/modules/travel-agency/dashboard/hotel/add-room/Breakfast.tsx to src/components/modules/travel-agency/hotel/add-room/Breakfast.tsx',
      'src/components/modules/travel-agency/dashboard/hotel/add-room/BreakfastMenu.tsx to src/components/modules/travel-agency/hotel/add-room/BreakfastMenu.tsx',
      'src/components/modules/travel-agency/dashboard/hotel/add-room/ConterForm.tsx to src/components/modules/travel-agency/hotel/add-room/ConterForm.tsx',
      'src/components/modules/travel-agency/dashboard/hotel/add-room/DemoForms.tsx to src/components/modules/travel-agency/hotel/add-room/DemoForms.tsx',
      'src/components/modules/travel-agency/dashboard/hotel/add-room/ExtraBed.tsx to src/components/modules/travel-agency/hotel/add-room/ExtraBed.tsx',
      'src/components/modules/travel-agency/dashboard/hotel/add-room/Preview.tsx to src/components/modules/travel-agency/hotel/add-room/Preview.tsx',
      'src/components/modules/travel-agency/dashboard/hotel/add-room/Pricing.tsx to src/components/modules/travel-agency/hotel/add-room/Pricing.tsx',
      'src/components/modules/travel-agency/dashboard/hotel/add-room/RoomDetailsForm.tsx to src/components/modules/travel-agency/hotel/add-room/RoomDetailsForm.tsx',
      'src/components/modules/travel-agency/dashboard/hotel/add-room/RoomWizardFooter.tsx to src/components/modules/travel-agency/hotel/add-room/RoomWizardFooter.tsx',
      'src/components/modules/travel-agency/dashboard/hotel/add-room/SummaryTable.tsx to src/components/modules/travel-agency/hotel/add-room/SummaryTable.tsx',
      'src/components/modules/travel-agency/dashboard/hotel/add-room/WeeklyPricingCard.tsx to src/components/modules/travel-agency/hotel/add-room/WeeklyPricingCard.tsx',
      'src/components/modules/travel-agency/dashboard/hotel/common/PriceTierForm.tsx to src/components/modules/travel-agency/hotel/common/PriceTierForm.tsx',
      'src/components/modules/travel-agency/dashboard/hotel/hotel-compare/CompareHotelDetails.tsx to src/components/modules/travel-agency/hotel/hotel-compare/CompareHotelDetails.tsx',
      'src/components/modules/travel-agency/dashboard/hotel/hotel-compare/CompareRoomDetails.tsx to src/components/modules/travel-agency/hotel/hotel-compare/CompareRoomDetails.tsx',
      'src/components/modules/travel-agency/dashboard/hotel/hotel-compare/RoomPictureSlider.tsx to src/components/modules/travel-agency/hotel/hotel-compare/RoomPictureSlider.tsx',
      'src/components/modules/travel-agency/dashboard/hotel/hotel-compare/SelectRoomCollapsibleContainer.tsx to src/components/modules/travel-agency/hotel/hotel-compare/SelectRoomCollapsibleContainer.tsx',
      'src/components/modules/travel-agency/dashboard/hotel/hotel-details/HotelDetailsAvailability.tsx to src/components/modules/travel-agency/hotel/hotel-details/HotelDetailsAvailability.tsx',
      'src/components/modules/travel-agency/dashboard/hotel/hotel-details/HotelDetailsAvailabilityRoomInfo.tsx to src/components/modules/travel-agency/hotel/hotel-details/HotelDetailsAvailabilityRoomInfo.tsx',
      'src/components/modules/travel-agency/dashboard/hotel/hotel-details/HotelDetailsDescription.tsx to src/components/modules/travel-agency/hotel/hotel-details/HotelDetailsDescription.tsx',
      'src/components/modules/travel-agency/dashboard/hotel/hotel-details/HotelDetailsFacilities.tsx to src/components/modules/travel-agency/hotel/hotel-details/HotelDetailsFacilities.tsx',
      'src/components/modules/travel-agency/dashboard/hotel/hotel-details/HotelDetailsMapBox.tsx to src/components/modules/travel-agency/hotel/hotel-details/HotelDetailsMapBox.tsx',
      'src/components/modules/travel-agency/dashboard/hotel/hotel-details/HotelDetailsPolicy.tsx to src/components/modules/travel-agency/hotel/hotel-details/HotelDetailsPolicy.tsx',
      'src/components/modules/travel-agency/dashboard/hotel/hotel-details/HotelDetailsRoomImageGallery.tsx to src/components/modules/travel-agency/hotel/hotel-details/HotelDetailsRoomImageGallery.tsx',
      'src/components/modules/travel-agency/dashboard/hotel/hotel-details/HotelDetailsTab.tsx to src/components/modules/travel-agency/hotel/hotel-details/HotelDetailsTab.tsx',
      'src/components/modules/travel-agency/dashboard/hotel/hotel-details/HotelDetailsReviews.tsx to src/components/modules/travel-agency/hotel/hotel-details/HotelReviews.tsx',
      'src/components/modules/travel-agency/dashboard/hotel/search-room/RoomCard.tsx to src/components/modules/travel-agency/hotel/search-room/RoomCard.tsx',
      'src/components/modules/travel-agency/dashboard/hotel/search-room/RoomFilterActions.tsx to src/components/modules/travel-agency/hotel/search-room/RoomFilterActions.tsx',
      'src/components/modules/travel-agency/dashboard/hotel/search-room/RoomFilterCheckbox.tsx to src/components/modules/travel-agency/hotel/search-room/RoomFilterCheckbox.tsx',
      'src/components/modules/travel-agency/dashboard/hotel/search-room/RoomFilterCollapseItem.tsx to src/components/modules/travel-agency/hotel/search-room/RoomFilterCollapseItem.tsx',
      'src/components/modules/travel-agency/dashboard/hotel/search-room/RoomFilterOffcanvas.tsx to src/components/modules/travel-agency/hotel/search-room/RoomFilterOffcanvas.tsx',
      'src/components/modules/travel-agency/dashboard/hotel/search-room/RoomFilterOffcanvasContent.tsx to src/components/modules/travel-agency/hotel/search-room/RoomFilterOffcanvasContent.tsx',
      'src/components/modules/travel-agency/dashboard/hotel/search-room/RoomFilterSearch.tsx to src/components/modules/travel-agency/hotel/search-room/RoomFilterSearch.tsx'
    ]
  },
  {
    from: 'v1.4.0',
    to: 'v1.5.0',
    new: [
      'src/assets/img/bg/42.png',
      'src/assets/img/bg/43.png',
      'src/assets/img/gallery/59.png',
      'src/assets/img/gallery/60.png',
      'src/assets/img/gallery/61.png',
      'src/assets/img/gallery/62.png',
      'src/assets/img/gallery/63.png',
      'src/assets/img/hotels/1.png',
      'src/assets/img/hotels/10.png',
      'src/assets/img/hotels/11.png',
      'src/assets/img/hotels/12.png',
      'src/assets/img/hotels/13.png',
      'src/assets/img/hotels/14.png',
      'src/assets/img/hotels/15.png',
      'src/assets/img/hotels/16.png',
      'src/assets/img/hotels/17.png',
      'src/assets/img/hotels/18.png',
      'src/assets/img/hotels/19.png',
      'src/assets/img/hotels/2.png',
      'src/assets/img/hotels/20.png',
      'src/assets/img/hotels/21.png',
      'src/assets/img/hotels/22.png',
      'src/assets/img/hotels/23.png',
      'src/assets/img/hotels/24.png',
      'src/assets/img/hotels/25.png',
      'src/assets/img/hotels/25_2.png',
      'src/assets/img/hotels/26.png',
      'src/assets/img/hotels/26_2.png',
      'src/assets/img/hotels/27.png',
      'src/assets/img/hotels/27_2.png',
      'src/assets/img/hotels/28.png',
      'src/assets/img/hotels/28_2.png',
      'src/assets/img/hotels/29.png',
      'src/assets/img/hotels/29_2.png',
      'src/assets/img/hotels/3.png',
      'src/assets/img/hotels/30.png',
      'src/assets/img/hotels/30_2.png',
      'src/assets/img/hotels/31.png',
      'src/assets/img/hotels/31_2.png',
      'src/assets/img/hotels/32.png',
      'src/assets/img/hotels/32_2.png',
      'src/assets/img/hotels/33.png',
      'src/assets/img/hotels/34.png',
      'src/assets/img/hotels/35.png',
      'src/assets/img/hotels/36.png',
      'src/assets/img/hotels/37.png',
      'src/assets/img/hotels/38.png',
      'src/assets/img/hotels/39.png',
      'src/assets/img/hotels/4.png',
      'src/assets/img/hotels/40.png',
      'src/assets/img/hotels/41.png',
      'src/assets/img/hotels/42.png',
      'src/assets/img/hotels/43.png',
      'src/assets/img/hotels/44.png',
      'src/assets/img/hotels/45.png',
      'src/assets/img/hotels/46.png',
      'src/assets/img/hotels/47.png',
      'src/assets/img/hotels/48.png',
      'src/assets/img/hotels/49.png',
      'src/assets/img/hotels/5.png',
      'src/assets/img/hotels/50.png',
      'src/assets/img/hotels/51.png',
      'src/assets/img/hotels/52.png',
      'src/assets/img/hotels/53.png',
      'src/assets/img/hotels/54.png',
      'src/assets/img/hotels/55.png',
      'src/assets/img/hotels/56.png',
      'src/assets/img/hotels/57.png',
      'src/assets/img/hotels/58.png',
      'src/assets/img/hotels/59.png',
      'src/assets/img/hotels/6.png',
      'src/assets/img/hotels/60.png',
      'src/assets/img/hotels/61.png',
      'src/assets/img/hotels/62.png',
      'src/assets/img/hotels/63.png',
      'src/assets/img/hotels/64.png',
      'src/assets/img/hotels/65.png',
      'src/assets/img/hotels/66.png',
      'src/assets/img/hotels/67.png',
      'src/assets/img/hotels/68.png',
      'src/assets/img/hotels/69.png',
      'src/assets/img/hotels/7.png',
      'src/assets/img/hotels/70.png',
      'src/assets/img/hotels/71.png',
      'src/assets/img/hotels/72.png',
      'src/assets/img/hotels/73.png',
      'src/assets/img/hotels/74.png',
      'src/assets/img/hotels/75.png',
      'src/assets/img/hotels/76.png',
      'src/assets/img/hotels/77.mp4',
      'src/assets/img/hotels/78.png',
      'src/assets/img/hotels/79.png',
      'src/assets/img/hotels/8.png',
      'src/assets/img/hotels/80.png',
      'src/assets/img/hotels/81.png',
      'src/assets/img/hotels/82.png',
      'src/assets/img/hotels/83.png',
      'src/assets/img/hotels/84.jpg',
      'src/assets/img/hotels/85.jpg',
      'src/assets/img/hotels/86.jpg',
      'src/assets/img/hotels/87.jpg',
      'src/assets/img/hotels/88.jpg',
      'src/assets/img/hotels/89.jpg',
      'src/assets/img/hotels/9.png',
      'src/assets/img/hotels/90.jpg',
      'src/assets/img/hotels/91.jpg',
      'src/assets/img/hotels/92.jpg',
      'src/assets/img/icons/bed-double.svg',
      'src/assets/img/icons/bed-double_dark.svg',
      'src/assets/img/icons/dollar-alt.svg',
      'src/assets/img/icons/dollar-alt_dark.svg',
      'src/assets/img/icons/file-check-alt.svg',
      'src/assets/img/icons/file-check-alt_dark.svg',
      'src/assets/img/icons/info.svg',
      'src/assets/img/icons/info_dark.svg',
      'src/assets/img/icons/location.svg',
      'src/assets/img/icons/location_dark.svg',
      'src/assets/img/icons/picture.svg',
      'src/assets/img/icons/picture_dark.svg',
      'src/assets/img/sections/module-1.png',
      'src/assets/img/sections/module-10.png',
      'src/assets/img/sections/module-11.png',
      'src/assets/img/sections/module-12.png',
      'src/assets/img/sections/module-13.png',
      'src/assets/img/sections/module-14.png',
      'src/assets/img/sections/module-15.png',
      'src/assets/img/sections/module-16.png',
      'src/assets/img/sections/module-2.png',
      'src/assets/img/sections/module-3.png',
      'src/assets/img/sections/module-4.png',
      'src/assets/img/sections/module-5.png',
      'src/assets/img/sections/module-6.png',
      'src/assets/img/sections/module-7.png',
      'src/assets/img/sections/module-8.png',
      'src/assets/img/sections/module-9.png',
      'src/assets/scss/theme/plugins/_react-range.scss',
      'src/components/cards/HotelCard.tsx',
      'src/components/footers/TravelFooter.tsx',
      'src/components/forms/PhoenixReactRange.tsx',
      'src/components/modules/showcase/DifferentModules.tsx',
      'src/components/modules/travel-agency/dashboard/hotel/GalleryItem.tsx',
      'src/components/modules/travel-agency/dashboard/hotel/GalleryItems.tsx',
      'src/components/modules/travel-agency/dashboard/hotel/HotelActions.tsx',
      'src/components/modules/travel-agency/dashboard/hotel/add-proterty/AddPhotos.tsx',
      'src/components/modules/travel-agency/dashboard/hotel/add-proterty/BasicInformationForm.tsx',
      'src/components/modules/travel-agency/dashboard/hotel/add-proterty/FinanceForm.tsx',
      'src/components/modules/travel-agency/dashboard/hotel/add-proterty/GeneralAmenitiesForm.tsx',
      'src/components/modules/travel-agency/dashboard/hotel/add-proterty/LocationForm.tsx',
      'src/components/modules/travel-agency/dashboard/hotel/add-proterty/PoliciesForm.tsx',
      'src/components/modules/travel-agency/dashboard/hotel/add-proterty/Preview.tsx',
      'src/components/modules/travel-agency/dashboard/hotel/add-proterty/SummaryTable.tsx',
      'src/components/modules/travel-agency/dashboard/hotel/add-room/Amenities.tsx',
      'src/components/modules/travel-agency/dashboard/hotel/add-room/Breakfast.tsx',
      'src/components/modules/travel-agency/dashboard/hotel/add-room/BreakfastMenu.tsx',
      'src/components/modules/travel-agency/dashboard/hotel/add-room/ConterForm.tsx',
      'src/components/modules/travel-agency/dashboard/hotel/add-room/DemoForms.tsx',
      'src/components/modules/travel-agency/dashboard/hotel/add-room/ExtraBed.tsx',
      'src/components/modules/travel-agency/dashboard/hotel/add-room/Preview.tsx',
      'src/components/modules/travel-agency/dashboard/hotel/add-room/Pricing.tsx',
      'src/components/modules/travel-agency/dashboard/hotel/add-room/RoomDetailsForm.tsx',
      'src/components/modules/travel-agency/dashboard/hotel/add-room/RoomWizardFooter.tsx',
      'src/components/modules/travel-agency/dashboard/hotel/add-room/SummaryTable.tsx',
      'src/components/modules/travel-agency/dashboard/hotel/add-room/WeeklyPricingCard.tsx',
      'src/components/modules/travel-agency/dashboard/hotel/common/PriceTierForm.tsx',
      'src/components/modules/travel-agency/dashboard/hotel/search-room/RoomCard.tsx',
      'src/components/modules/travel-agency/dashboard/hotel/search-room/RoomFilterActions.tsx',
      'src/components/modules/travel-agency/dashboard/hotel/search-room/RoomFilterCheckbox.tsx',
      'src/components/modules/travel-agency/dashboard/hotel/search-room/RoomFilterCollapseItem.tsx',
      'src/components/modules/travel-agency/dashboard/hotel/search-room/RoomFilterOffcanvas.tsx',
      'src/components/modules/travel-agency/dashboard/hotel/search-room/RoomFilterOffcanvasContent.tsx',
      'src/components/modules/travel-agency/dashboard/hotel/search-room/RoomFilterSearch.tsx',
      'src/components/navbars/travel-agency/NavbarHome.tsx',
      'src/components/tables/RoomListingTable.tsx',
      'src/components/wizard/WizardNavItem.tsx',
      'src/components/wizard/WizardSideNav.tsx',
      'src/data/travel-agency/addProperty.ts',
      'src/data/travel-agency/addRoom.ts',
      'src/data/travel-agency/admin/searchRoom.ts',
      'src/data/travel-agency/customer/gallery.ts',
      'src/data/travel-agency/customer/hotel.ts',
      'src/data/travel-agency/roomListing.ts',
      'src/data/wizard/wizard.ts',
      'src/layouts/TravelAgencyLayout.tsx',
      'src/layouts/TravelLandingLayout.tsx',
      'src/pages/apps/travel-agency/hotel/admin/AddProperty.tsx',
      'src/pages/apps/travel-agency/hotel/admin/AddRoom.tsx',
      'src/pages/apps/travel-agency/hotel/admin/RoomListing.tsx',
      'src/pages/apps/travel-agency/hotel/admin/SearchRoom.tsx',
      'src/pages/apps/travel-agency/hotel/customer/HotelGallery.tsx',
      'src/pages/apps/travel-agency/hotel/customer/homepage/Homepage.tsx',
      'src/pages/modules/forms/advance/RangeExample.tsx'
    ],
    update: [
      'src/assets/scss/theme/_accordion.scss',
      'src/assets/scss/theme/_ecommerce.scss',
      'src/assets/scss/theme/_forms.scss',
      'src/assets/scss/theme/_landing.scss',
      'src/assets/scss/theme/_mixed.scss',
      'src/assets/scss/theme/_navbar.scss',
      'src/assets/scss/theme/_offcanvas.scss',
      'src/assets/scss/theme/_plugins.scss',
      'src/assets/scss/theme/_showcase.scss',
      'src/assets/scss/theme/_wizard.scss',
      'src/assets/scss/theme/root/_light.scss',
      'src/components/base/Dropzone.tsx',
      'src/components/base/LightBox.tsx',
      'src/components/base/MapBox.tsx',
      'src/components/base/Swiper.tsx',
      'src/components/cards/EcomPayingVsNonPayingCard.tsx',
      'src/components/cards/EcomTopCouponsCard.tsx',
      'src/components/cards/EcomTotalOrdersCard.tsx',
      'src/components/cards/EventTitleCard.tsx',
      'src/components/cards/HolidaysCard.tsx',
      'src/components/charts/e-charts/CommissionChart.tsx',
      'src/components/common/CoverUpload.tsx',
      'src/components/common/ProductCard.tsx',
      'src/components/modals/CalendarEventModal.tsx',
      'src/components/modals/KanbanAddListModal.tsx',
      'src/components/modules/crm/deals-details/DealDetailsInfo.tsx',
      'src/components/modules/events/ResponsesAndShare.tsx',
      'src/components/modules/landing/alternate/Gallery.tsx',
      'src/components/modules/landing/default/Gallery.tsx',
      'src/components/modules/showcase/Demos.tsx',
      'src/components/modules/showcase/NecessaryPages.tsx',
      'src/components/modules/travel-agency/dashboard/FlightsHeader.tsx',
      'src/components/pricing-items/PricingGridItem.tsx',
      'src/components/wizard/WizardFormFooter.tsx',
      'src/components/wizard/WizardNav.tsx',
      'src/helpers/echart-utils.ts',
      'src/helpers/utils.ts',
      'src/pages/Showcase.tsx',
      'src/pages/apps/travel-agency/landing/Landing.tsx',
      'src/pages/apps/travel-agency/landing/SeasonOfTour.tsx'
    ]
  },
  {
    from: 'v1.3.0',
    to: 'v1.4.0',
    new: [
      'src/assets/img/bg/41.jpg',
      'src/assets/img/bg/bg-left-27.png',
      'src/assets/img/bg/bg-left-28.png',
      'src/assets/img/bg/bg-left-29.png',
      'src/assets/img/bg/bg-left-30.png',
      'src/assets/img/bg/bg-left-31.png',
      'src/assets/img/bg/bg-left-32.png',
      'src/assets/img/bg/bg-left-33.png',
      'src/assets/img/bg/bg-right-27.png',
      'src/assets/img/bg/bg-right-28.png',
      'src/assets/img/bg/bg-right-30.png',
      'src/assets/img/bg/bg-right-31.png',
      'src/assets/img/bg/bg-right-32.png',
      'src/assets/img/bg/bg-right-33.png',
      'src/assets/img/brands/emirates.png',
      'src/assets/img/brands/jal.png',
      'src/assets/img/brands/phoenix-firelines.png',
      'src/assets/img/brands/qatar-airways.png',
      'src/assets/img/country/canada.png',
      'src/assets/img/country/china-2.png',
      'src/assets/img/country/denmark.png',
      'src/assets/img/country/india-2.png',
      'src/assets/img/country/new-zealand.png',
      'src/assets/img/country/norway.png',
      'src/assets/img/country/qatar.png',
      'src/assets/img/country/sweden.png',
      'src/assets/img/country/switzerland.png',
      'src/assets/img/country/thailand.png',
      'src/assets/img/country/turkey.png',
      'src/assets/img/country/usa-2.png',
      'src/assets/img/gallery/35.png',
      'src/assets/img/gallery/36.png',
      'src/assets/img/gallery/37.png',
      'src/assets/img/gallery/38.png',
      'src/assets/img/gallery/39.png',
      'src/assets/img/gallery/40.png',
      'src/assets/img/gallery/41.png',
      'src/assets/img/gallery/42.png',
      'src/assets/img/gallery/43.png',
      'src/assets/img/gallery/44.png',
      'src/assets/img/gallery/45.png',
      'src/assets/img/gallery/46.png',
      'src/assets/img/gallery/47.png',
      'src/assets/img/gallery/48.png',
      'src/assets/img/gallery/49.png',
      'src/assets/img/gallery/50.png',
      'src/assets/img/gallery/51.png',
      'src/assets/img/gallery/52.png',
      'src/assets/img/gallery/53.png',
      'src/assets/img/gallery/54.png',
      'src/assets/img/gallery/55.png',
      'src/assets/img/gallery/56.png',
      'src/assets/img/gallery/57.png',
      'src/assets/img/gallery/58.png',
      'src/assets/img/gallery/64.png',
      'src/assets/img/gallery/bali-1.png',
      'src/assets/img/gallery/bali-2.png',
      'src/assets/img/gallery/bali-3.png',
      'src/assets/img/gallery/paris-1.png',
      'src/assets/img/gallery/paris-2.png',
      'src/assets/img/gallery/paris-3.png',
      'src/assets/img/gallery/sydney-1.png',
      'src/assets/img/gallery/sydney-2.png',
      'src/assets/img/gallery/sydney-3.png',
      'src/assets/img/gallery/tokyo-1.png',
      'src/assets/img/gallery/tokyo-2.png',
      'src/assets/img/gallery/tokyo-3.png',
      'src/assets/img/generic/app-store.png',
      'src/assets/img/generic/play-store.png',
      'src/assets/img/icons/fly-map-marker.png',
      'src/assets/img/icons/fly-map-marker_dark.png',
      'src/assets/img/icons/land-map-marker.png',
      'src/assets/img/icons/land-map-marker_dark.png',
      'src/assets/img/icons/plane.png',
      'src/assets/img/icons/plane_dark.png',
      'src/assets/img/spot-illustrations/41.png',
      'src/assets/img/spot-illustrations/42.png',
      'src/assets/img/spot-illustrations/dark_41.png',
      'src/assets/img/spot-illustrations/dark_42.png',
      'src/assets/img/spot-illustrations/i-phone-dark.png',
      'src/assets/img/spot-illustrations/i-phone.png',
      'src/assets/img/spot-illustrations/interations.png',
      'src/assets/scss/theme/_travel-agency.scss',
      'src/assets/video/travel.mp4',
      'src/assets/video/travel.png',
      'src/components/base/basicDropdown.tsx',
      'src/components/cards/GrossProfitCard.tsx',
      'src/components/cards/HolidaysCard.tsx',
      'src/components/cards/VisitorsCard.tsx',
      'src/components/charts/e-charts/BaseLineChart.tsx',
      'src/components/charts/e-charts/BasicEcharts.tsx',
      'src/components/charts/e-charts/BookingsChart.tsx',
      'src/components/charts/e-charts/CommissionChart.tsx',
      'src/components/charts/e-charts/CountryWiseVisitorsChart.tsx',
      'src/components/charts/e-charts/FinancialActivitiesChart.tsx',
      'src/components/charts/e-charts/GrossProfitChart.tsx',
      'src/components/charts/e-charts/HolidaysNextMonth.tsx',
      'src/components/cta/IntegrationsCard.tsx',
      'src/components/cta/TravelCta.tsx',
      'src/components/modules/travel-agency/dashboard/Bookings.tsx',
      'src/components/modules/travel-agency/dashboard/FinancialActivities.tsx',
      'src/components/modules/travel-agency/dashboard/FlightMap.tsx',
      'src/components/modules/travel-agency/dashboard/FlightTable.tsx',
      'src/components/modules/travel-agency/dashboard/Flights.tsx',
      'src/components/modules/travel-agency/dashboard/FlightsHeader.tsx',
      'src/components/modules/travel-agency/dashboard/TravelStatistics.tsx',
      'src/components/navbars/travel-landing/NavbarMain.tsx',
      'src/components/stats/TravelStats.tsx',
      'src/components/tables/TravelFlightTable.tsx',
      'src/components/tables/VisitorsTable.tsx',
      'src/data/travel-agency/landing.ts',
      'src/data/travel-agency/travelAgency.ts',
      'src/pages/apps/travel-agency/landing/BestHotel.tsx',
      'src/pages/apps/travel-agency/landing/BestPlaces.tsx',
      'src/pages/apps/travel-agency/landing/Footer.tsx',
      'src/pages/apps/travel-agency/landing/Gallery.tsx',
      'src/pages/apps/travel-agency/landing/GetApp.tsx',
      'src/pages/apps/travel-agency/landing/HeroHeader.tsx',
      'src/pages/apps/travel-agency/landing/Landing.tsx',
      'src/pages/apps/travel-agency/landing/LatestPhotos.tsx',
      'src/pages/apps/travel-agency/landing/LatestPosts.tsx',
      'src/pages/apps/travel-agency/landing/SeasonOfTour.tsx',
      'src/pages/apps/travel-agency/landing/TopNav.tsx',
      'src/pages/dashboard/TravelAgency.tsx'
    ],
    update: [
      'package.json',
      'src/Routes.tsx',
      'src/assets/scss/theme/_buttons.scss',
      'src/assets/scss/theme/_container.scss',
      'src/assets/scss/theme/_forms.scss',
      'src/assets/scss/theme/_landing.scss',
      'src/assets/scss/theme/_mixed.scss',
      'src/assets/scss/theme/_navbar.scss',
      'src/assets/scss/theme/_table.scss',
      'src/assets/scss/theme/_theme.scss',
      'src/assets/scss/theme/_variables.scss',
      'src/assets/scss/theme/helpers/_background.scss',
      'src/assets/scss/theme/plugins/_mapbox.scss',
      'src/assets/scss/theme/plugins/_swiper.scss',
      'src/assets/scss/theme/plugins/_tinymce.scss',
      'src/assets/scss/theme/root/_dark.scss',
      'src/assets/scss/theme/root/_light.scss',
      'src/components/base/AdvanceTableFooter.tsx',
      'src/components/base/TinymceEditor.tsx',
      'src/components/common/CommentForm.tsx',
      'src/components/modals/CalendarEventModal.tsx',
      'src/components/modals/DealsAddStageModal.tsx',
      'src/components/modals/FilterDealsModal.tsx',
      'src/components/modals/KanbanAddListModal.tsx',
      'src/components/modals/KanbanInviteModal.tsx',
      'src/components/modals/LeadsFilterModal.tsx',
      'src/components/modals/ReportFilterModal.tsx',
      'src/components/modals/ReportsFilterModal.tsx',
      'src/components/modules/crm/deals/AddDealModal.tsx',
      'src/components/modules/kanban/KanbanBoardOffcanvas.tsx',
      'src/components/modules/landing/alternate/features/FeatureSection.tsx',
      'src/components/modules/landing/default/Footer.tsx',
      'src/components/modules/project-management/todo-list/TodoItemDetailsModal.tsx',
      'src/components/navbars/navbar-horizontal/TopNavItem.tsx',
      'src/components/navbars/navbar-horizontal/TopNavMegaMenu.tsx',
      'src/components/navbars/navbar-vertical/NavbarVerticalMenu.tsx',
      'src/components/navs/IsotopeNav.tsx',
      'src/components/wizard/WizardAccessDeniedModal.tsx',
      'src/data/changelog.ts',
      'src/hooks/useToggleStyle.tsx',
      'src/providers/AppProvider.tsx',
      'src/sitemap.tsx',
      'types/phoenix-module.d.ts'
    ]
  },
  {
    from: 'v1.2.0',
    to: 'v1.3.0',
    new: [
      'src/assets/img/generic/auto.png',
      'src/assets/scss/theme/_brand-colors.scss',
      'src/assets/scss/theme/_navbar-appearance.scss',
      'src/assets/scss/theme/_variables-dark.scss',
      'src/components/docs/ThemeDropdown.tsx',
      'src/data/changelogList.tsx',
      'src/pages/documentation/customization/Color.tsx'
    ],
    update: [
      'package-lock.json',
      'package.json',
      'src/Routes.tsx',
      'src/assets/img/sections/Demos.pug',
      'src/assets/scss/_bootstrap.scss',
      'src/assets/scss/theme.scss',
      'src/assets/scss/theme/_alert.scss',
      'src/assets/scss/theme/_auth.scss',
      'src/assets/scss/theme/_avatar.scss',
      'src/assets/scss/theme/_badges.scss',
      'src/assets/scss/theme/_buttons.scss',
      'src/assets/scss/theme/_carousel.scss',
      'src/assets/scss/theme/_chat-widget.scss',
      'src/assets/scss/theme/_chat.scss',
      'src/assets/scss/theme/_colors.scss',
      'src/assets/scss/theme/_crm.scss',
      'src/assets/scss/theme/_documentation.scss',
      'src/assets/scss/theme/_dropdown.scss',
      'src/assets/scss/theme/_ecommerce.scss',
      'src/assets/scss/theme/_email.scss',
      'src/assets/scss/theme/_feed.scss',
      'src/assets/scss/theme/_forms.scss',
      'src/assets/scss/theme/_functions.scss',
      'src/assets/scss/theme/_gantt-chart.scss',
      'src/assets/scss/theme/_hover.scss',
      'src/assets/scss/theme/_icon.scss',
      'src/assets/scss/theme/_indicator.scss',
      'src/assets/scss/theme/_kanban.scss',
      'src/assets/scss/theme/_landing.scss',
      'src/assets/scss/theme/_maps.scss',
      'src/assets/scss/theme/_mixed.scss',
      'src/assets/scss/theme/_modal.scss',
      'src/assets/scss/theme/_nav-tab.scss',
      'src/assets/scss/theme/_navbar-bottom.scss',
      'src/assets/scss/theme/_navbar-top.scss',
      'src/assets/scss/theme/_navbar-vertical.scss',
      'src/assets/scss/theme/_navbar.scss',
      'src/assets/scss/theme/_pricing.scss',
      'src/assets/scss/theme/_progress.scss',
      'src/assets/scss/theme/_reboot.scss',
      'src/assets/scss/theme/_root.scss',
      'src/assets/scss/theme/_search-box.scss',
      'src/assets/scss/theme/_setting-panel.scss',
      'src/assets/scss/theme/_table.scss',
      'src/assets/scss/theme/_theme.scss',
      'src/assets/scss/theme/_timeline.scss',
      'src/assets/scss/theme/_tooltip.scss',
      'src/assets/scss/theme/_type.scss',
      'src/assets/scss/theme/_utilities.scss',
      'src/assets/scss/theme/_variables.scss',
      'src/assets/scss/theme/_wizard.scss',
      'src/assets/scss/theme/helpers/_background.scss',
      'src/assets/scss/theme/helpers/_borders.scss',
      'src/assets/scss/theme/helpers/_colored-links.scss',
      'src/assets/scss/theme/helpers/_text.scss',
      'src/assets/scss/theme/mixins/_buttons.scss',
      'src/assets/scss/theme/plugins/_choices.scss',
      'src/assets/scss/theme/plugins/_flatpickr.scss',
      'src/assets/scss/theme/plugins/_full-calendar.scss',
      'src/assets/scss/theme/plugins/_isotope.scss',
      'src/assets/scss/theme/plugins/_leaflet.scss',
      'src/assets/scss/theme/plugins/_list.scss',
      'src/assets/scss/theme/plugins/_picmo.scss',
      'src/assets/scss/theme/plugins/_react-dropzone.scss',
      'src/assets/scss/theme/plugins/_react-select.scss',
      'src/assets/scss/theme/plugins/_swiper.scss',
      'src/assets/scss/theme/plugins/_tinymce.scss',
      'src/assets/scss/theme/root/_dark.scss',
      'src/assets/scss/theme/root/_light.scss',
      'src/assets/scss/theme/root/_override.scss',
      'src/components/banners/EcomBestInMarketBanner.tsx',
      'src/components/banners/EcomGiftItemsBanner.tsx',
      'src/components/banners/EcomWhopingBanner.tsx',
      'src/components/base/AdvanceTable.tsx',
      'src/components/base/AdvanceTableFooter.tsx',
      'src/components/base/Avatar.tsx',
      'src/components/base/Button.tsx',
      'src/components/base/CheckButton.tsx',
      'src/components/base/DatePicker.tsx',
      'src/components/base/Dropzone.tsx',
      'src/components/base/MapBox.tsx',
      'src/components/base/PhoenixDocCard.tsx',
      'src/components/base/PhoenixOffcanvas.tsx',
      'src/components/base/Rating.tsx',
      'src/components/base/ReactSelect.tsx',
      'src/components/base/SeeMoreText.tsx',
      'src/components/base/TinymceEditor.tsx',
      'src/components/cards/AboutLeadCard.tsx',
      'src/components/cards/ChatHomepageCard.tsx',
      'src/components/cards/CrmStatCard.tsx',
      'src/components/cards/CustomerNotesCard.tsx',
      'src/components/cards/CustomerProfileCard.tsx',
      'src/components/cards/DealCard.tsx',
      'src/components/cards/DealProfileCard.tsx',
      'src/components/cards/DealsPrintingDimensionsCard.tsx',
      'src/components/cards/EarlyBirdCard.tsx',
      'src/components/cards/EcoimDefaultAddressCard.tsx',
      'src/components/cards/EcomCartSummaryCard.tsx',
      'src/components/cards/EcomNewCustomersCard.tsx',
      'src/components/cards/EcomPayingVsNonPayingCard.tsx',
      'src/components/cards/EcomProfileCard.tsx',
      'src/components/cards/EcomTopCouponsCard.tsx',
      'src/components/cards/EcomTotalOrdersCard.tsx',
      'src/components/cards/EventTitleCard.tsx',
      'src/components/cards/IconCard.tsx',
      'src/components/cards/MeetingCard.tsx',
      'src/components/cards/OrderDetailsSummaryCard.tsx',
      'src/components/cards/OrganizeFormCard.tsx',
      'src/components/cards/ProjectActivityCard.tsx',
      'src/components/cards/ReportCard.tsx',
      'src/components/cards/SocialCoverCard.tsx',
      'src/components/cards/SocialProfileCard.tsx',
      'src/components/cards/UsuallyBoughtTogetherCard.tsx',
      'src/components/cards/VariantFormCard.tsx',
      'src/components/charts/e-charts/AdClicksChart.tsx',
      'src/components/charts/e-charts/AnalyticsCallCampaignChart.tsx',
      'src/components/charts/e-charts/AnalyticsEmailCampaignChart.tsx',
      'src/components/charts/e-charts/AnalyticsSalesTrendsChart.tsx',
      'src/components/charts/e-charts/BasicLineChart.tsx',
      'src/components/charts/e-charts/CompletedTaskChart.tsx',
      'src/components/charts/e-charts/ContactsBySourceChart.tsx',
      'src/components/charts/e-charts/ContactsCreatedChart.tsx',
      'src/components/charts/e-charts/EcomNewCustomersChart.tsx',
      'src/components/charts/e-charts/EcomPayingVsNonPayingChart.tsx',
      'src/components/charts/e-charts/EcomProjectionVsActualChart.tsx',
      'src/components/charts/e-charts/EcomReturningCustomerRateChart.tsx',
      'src/components/charts/e-charts/EcomTopCouponsChart.tsx',
      'src/components/charts/e-charts/EcomTotalOrdersChart.tsx',
      'src/components/charts/e-charts/EcomTotalSellsChart.tsx',
      'src/components/charts/e-charts/IssuesDiscoveredChart.tsx',
      'src/components/charts/e-charts/LeadConversationChart.tsx',
      'src/components/charts/e-charts/MarketingCampaignChart.tsx',
      'src/components/charts/e-charts/ProjectElevenProgressChart.tsx',
      'src/components/charts/e-charts/ReportDetailsChart.tsx',
      'src/components/charts/e-charts/RevenueTargetChart.tsx',
      'src/components/charts/e-charts/example/DoughnutPieChart.tsx',
      'src/components/charts/e-charts/example/SeriesBarChart.tsx',
      'src/components/charts/e-charts/example/SimpleLineChart.tsx',
      'src/components/charts/e-charts/example/StackedLineChart.tsx',
      'src/components/common/AttachmentPreview.tsx',
      'src/components/common/AvatarDropdown.tsx',
      'src/components/common/AvatarDropzone.tsx',
      'src/components/common/AvatarUpload.tsx',
      'src/components/common/ChartLegend.tsx',
      'src/components/common/CheckboxItem.tsx',
      'src/components/common/Comment.tsx',
      'src/components/common/CommentForm.tsx',
      'src/components/common/CoverUpload.tsx',
      'src/components/common/DropdownSearchBox.tsx',
      'src/components/common/EditableDetailsField.tsx',
      'src/components/common/FilterTab.tsx',
      'src/components/common/FormCollapse.tsx',
      'src/components/common/NotificationItem.tsx',
      'src/components/common/OrderSummaryDetails.tsx',
      'src/components/common/ProductCard.tsx',
      'src/components/common/SearchResult.tsx',
      'src/components/common/StoreItem.tsx',
      'src/components/common/TeamMemberDefault.tsx',
      'src/components/common/ToggleViewbutton.tsx',
      'src/components/common/TooltipIconButton.tsx',
      'src/components/common/WarningMessage.tsx',
      'src/components/common/chat-widget/ChatWidget.tsx',
      'src/components/common/chat-widget/ChatWidgetConversation.tsx',
      'src/components/common/chat-widget/ChatWidgetFooter.tsx',
      'src/components/cta/EcomBecomeMember.tsx',
      'src/components/cta/FaqCta.tsx',
      'src/components/docs/DocPageHeader.tsx',
      'src/components/footers/EcommerceFooter.tsx',
      'src/components/footers/Footer.tsx',
      'src/components/forms/DealsOtherInformation.tsx',
      'src/components/forms/EcomProfilePersonalInfo.tsx',
      'src/components/forms/EventTicketPricing.tsx',
      'src/components/forms/EventsSchedule.tsx',
      'src/components/forms/FeedTextarea.tsx',
      'src/components/forms/ProductVariant.tsx',
      'src/components/forms/SettingsChangePassword.tsx',
      'src/components/forms/SettingsCompanyInfo.tsx',
      'src/components/forms/SettingsPersonalInfo.tsx',
      'src/components/forms/SettingsSocial.tsx',
      'src/components/forms/WizardAccountForm.tsx',
      'src/components/grid-list-items/ContactSourceItem.tsx',
      'src/components/info-items/LeadInfoItem.tsx',
      'src/components/leaflet-maps/EcomTopRegionsMap.tsx',
      'src/components/list-items/EventItem.tsx',
      'src/components/list-items/EventsTopicCovered.tsx',
      'src/components/list-items/LeadSources.tsx',
      'src/components/list-items/MutualNavigation.tsx',
      'src/components/list-items/PricingPackageList.tsx',
      'src/components/list-items/ProductReview.tsx',
      'src/components/list-items/ProfileNavigation.tsx',
      'src/components/list-items/SocialMessages.tsx',
      'src/components/modals/CalendarAddNewEventModal.tsx',
      'src/components/modals/CalendarEventModal.tsx',
      'src/components/modals/DealsAddStageModal.tsx',
      'src/components/modals/FilterDealsModal.tsx',
      'src/components/modals/KanbanAddListModal.tsx',
      'src/components/modals/KanbanInviteModal.tsx',
      'src/components/modals/LeadsFilterModal.tsx',
      'src/components/modals/ReportFilterModal.tsx',
      'src/components/modals/ReportsFilterModal.tsx',
      'src/components/modals/ReviewModal.tsx',
      'src/components/modules/auth/ForgotPasswordForm.tsx',
      'src/components/modules/auth/LockScreenForm.tsx',
      'src/components/modules/auth/ResetPasswordForm.tsx',
      'src/components/modules/auth/SignInForm.tsx',
      'src/components/modules/auth/SignOutForm.tsx',
      'src/components/modules/auth/SignUpForm.tsx',
      'src/components/modules/auth/TwoFAForm.tsx',
      'src/components/modules/chat/ChatSidebar.tsx',
      'src/components/modules/chat/UserList.tsx',
      'src/components/modules/chat/chat-content/ChatContentFooter.tsx',
      'src/components/modules/chat/chat-content/ChatContentHeader.tsx',
      'src/components/modules/chat/chat-content/ConversationStarter.tsx',
      'src/components/modules/chat/chat-content/index.tsx',
      'src/components/modules/chat/conversation-details/ActionButton.tsx',
      'src/components/modules/chat/conversation-details/SharedFiles.tsx',
      'src/components/modules/chat/conversation-details/SharedMedia.tsx',
      'src/components/modules/chat/conversation-details/index.tsx',
      'src/components/modules/chat/message/MessageActionButtons.tsx',
      'src/components/modules/chat/message/index.tsx',
      'src/components/modules/crm/AdClicks.tsx',
      'src/components/modules/crm/ContactsBySource.tsx',
      'src/components/modules/crm/DealForecast.tsx',
      'src/components/modules/crm/LeadAttachments.tsx',
      'src/components/modules/crm/LeadConversion.tsx',
      'src/components/modules/crm/LeadDetailsNavbar.tsx',
      'src/components/modules/crm/NewUsersAndLeads.tsx',
      'src/components/modules/crm/ReportTopSection.tsx',
      'src/components/modules/crm/RevenueTarget.tsx',
      'src/components/modules/crm/Tasks.tsx',
      'src/components/modules/crm/analytics/CallCampaignReport.tsx',
      'src/components/modules/crm/analytics/EmailCampaign.tsx',
      'src/components/modules/crm/analytics/MarketingCampaign.tsx',
      'src/components/modules/crm/analytics/SalesTrends.tsx',
      'src/components/modules/crm/deals-details/DealDetailsAttachments.tsx',
      'src/components/modules/crm/deals-details/DealDetailsInfo.tsx',
      'src/components/modules/crm/deals-details/DealDetailsMeeting.tsx',
      'src/components/modules/crm/deals-details/DealDetailsNotes.tsx',
      'src/components/modules/crm/deals-details/DealDetailsTask.tsx',
      'src/components/modules/crm/deals/AddDealModal.tsx',
      'src/components/modules/crm/deals/DealColumn.tsx',
      'src/components/modules/crm/report-details/ReportDetailsHeader.tsx',
      'src/components/modules/e-commerce/OrderDeliveryDetails.tsx',
      'src/components/modules/e-commerce/ProductDescription.tsx',
      'src/components/modules/e-commerce/ProductDetailsTab.tsx',
      'src/components/modules/e-commerce/ProductGallery.tsx',
      'src/components/modules/e-commerce/ProductSpecificationTables.tsx',
      'src/components/modules/e-commerce/checkout/CheckoutSummaryCard.tsx',
      'src/components/modules/e-commerce/checkout/DeliveryOptionRadioItem.tsx',
      'src/components/modules/e-commerce/checkout/PaymentMethod.tsx',
      'src/components/modules/e-commerce/dashboard/EcomProjectionVsActual.tsx',
      'src/components/modules/e-commerce/dashboard/EcomReturningCustomerRate.tsx',
      'src/components/modules/e-commerce/dashboard/EcomTopRegions.tsx',
      'src/components/modules/e-commerce/dashboard/EcomTotalSells.tsx',
      'src/components/modules/e-commerce/profile/MyFavoriteStores.tsx',
      'src/components/modules/e-commerce/profile/ProfileDetailsTab.tsx',
      'src/components/modules/email/EmailRow.tsx',
      'src/components/modules/email/EmailSidebar.tsx',
      'src/components/modules/email/InboxToolbar.tsx',
      'src/components/modules/events/Events.tsx',
      'src/components/modules/events/EventsOrganized.tsx',
      'src/components/modules/events/EventsSingersSection.tsx',
      'src/components/modules/events/Location.tsx',
      'src/components/modules/events/ResponsesAndShare.tsx',
      'src/components/modules/faq/CategoryTab.tsx',
      'src/components/modules/faq/FaqCategoryCard.tsx',
      'src/components/modules/faq/SubCategoryContent.tsx',
      'src/components/modules/kanban/KanbanAddBoardCard.tsx',
      'src/components/modules/kanban/KanbanAttachment.tsx',
      'src/components/modules/kanban/KanbanBoardCard.tsx',
      'src/components/modules/kanban/KanbanBoardOffcanvas.tsx',
      'src/components/modules/kanban/KanbanBoardSection.tsx',
      'src/components/modules/kanban/KanbanEditTaskModal.tsx',
      'src/components/modules/kanban/KanbanHeader.tsx',
      'src/components/modules/kanban/KanbanList.tsx',
      'src/components/modules/kanban/KanbanListHeader.tsx',
      'src/components/modules/kanban/KanbanListItemCard.tsx',
      'src/components/modules/kanban/KanbanTaskDetailsModal.tsx',
      'src/components/modules/kanban/create-board/AccessForm.tsx',
      'src/components/modules/kanban/create-board/BackgroundColorForm.tsx',
      'src/components/modules/kanban/create-board/BoardForm.tsx',
      'src/components/modules/kanban/create-board/ColumnForm.tsx',
      'src/components/modules/kanban/create-board/StepProgressBoard.tsx',
      'src/components/modules/kanban/create-board/TagsForm.tsx',
      'src/components/modules/landing/alternate/Cta.tsx',
      'src/components/modules/landing/alternate/Footer.tsx',
      'src/components/modules/landing/alternate/HeroHeader.tsx',
      'src/components/modules/landing/alternate/Stats.tsx',
      'src/components/modules/landing/alternate/TeamSection.tsx',
      'src/components/modules/landing/alternate/blogs/BlogItem.tsx',
      'src/components/modules/landing/alternate/pricing/PricingItem.tsx',
      'src/components/modules/landing/default/Brands.tsx',
      'src/components/modules/landing/default/Cta.tsx',
      'src/components/modules/landing/default/Footer.tsx',
      'src/components/modules/landing/default/address/HelpCenter.tsx',
      'src/components/modules/landing/default/blog/BlogItem.tsx',
      'src/components/modules/landing/default/features/Features.tsx',
      'src/components/modules/landing/default/fun-facts/FunFacts.tsx',
      'src/components/modules/landing/default/pricing/Pricing.tsx',
      'src/components/modules/landing/default/pricing/PricingItem.tsx',
      'src/components/modules/landing/default/team/TeamSection.tsx',
      'src/components/modules/landing/default/testimonial/Testimonial.tsx',
      'src/components/modules/project-management/board-view/ActionSection.tsx',
      'src/components/modules/project-management/board-view/BoardViewItem.tsx',
      'src/components/modules/project-management/board-view/BoardViewModal.tsx',
      'src/components/modules/project-management/board-view/CoverImage.tsx',
      'src/components/modules/project-management/card-view/CardViewItem.tsx',
      'src/components/modules/project-management/card-view/CardViewModal.tsx',
      'src/components/modules/project-management/dashboard/IssuesDiscovered.tsx',
      'src/components/modules/project-management/dashboard/ProjectDashboard.tsx',
      'src/components/modules/project-management/dashboard/ProjectElevenProgress.tsx',
      'src/components/modules/project-management/dashboard/Stat.tsx',
      'src/components/modules/project-management/dashboard/ZeroRoadMap.tsx',
      'src/components/modules/project-management/project-details/ProjectDetailsSummary.tsx',
      'src/components/modules/project-management/project-details/TaskCompleted.tsx',
      'src/components/modules/project-management/project-details/TeamMembers.tsx',
      'src/components/modules/project-management/project-details/WorkLoads.tsx',
      'src/components/modules/project-management/todo-list/FileListItem.tsx',
      'src/components/modules/project-management/todo-list/SubTask.tsx',
      'src/components/modules/project-management/todo-list/TodoItemDetailsModal.tsx',
      'src/components/modules/project-management/todo-list/TodoItemDetailsOffcanvas.tsx',
      'src/components/modules/project-management/todo-list/TodoList.tsx',
      'src/components/modules/project-management/todo-list/TodoListItem.tsx',
      'src/components/modules/project-management/useProjectProgress.tsx',
      'src/components/modules/showcase/AdvanceFeatures.tsx',
      'src/components/modules/showcase/Cta.tsx',
      'src/components/modules/showcase/Demos.tsx',
      'src/components/modules/showcase/EssentialFeatures.tsx',
      'src/components/modules/showcase/Faq.tsx',
      'src/components/modules/showcase/Feature.tsx',
      'src/components/modules/showcase/FeatureFigma.tsx',
      'src/components/modules/showcase/Header.tsx',
      'src/components/modules/showcase/ImportantApplications.tsx',
      'src/components/modules/showcase/NecessaryPages.tsx',
      'src/components/modules/showcase/PreFooter.tsx',
      'src/components/modules/social/PostCard.tsx',
      'src/components/modules/social/PostComments.tsx',
      'src/components/modules/social/SocialSettingsSideBar.tsx',
      'src/components/modules/widgets/WidgetStats.tsx',
      'src/components/modules/widgets/WidgetTables.tsx',
      'src/components/modules/widgets/WidgetsScrollspyNav.tsx',
      'src/components/modules/widgets/WidgetsSectionTitle.tsx',
      'src/components/navbars/ShowcaseNavbar.tsx',
      'src/components/navbars/default-landing-navbar/DefaultLandingNavbar.tsx',
      'src/components/navbars/ecommerce/EcommerceNavbar.tsx',
      'src/components/navbars/nav-items/NavbarBrand.tsx',
      'src/components/navbars/nav-items/NineDotMenu.tsx',
      'src/components/navbars/nav-items/NotificationDropdownMenu.tsx',
      'src/components/navbars/nav-items/ProfileDropdownMenu.tsx',
      'src/components/navbars/navbar-dual/NavbarDual.tsx',
      'src/components/navbars/navbar-horizontal/NavbarTopHorizontal.tsx',
      'src/components/navbars/navbar-horizontal/TopNavItem.tsx',
      'src/components/navbars/navbar-horizontal/TopNavMegaMenu.tsx',
      'src/components/navbars/navbar-top/NavbarTopDefault.tsx',
      'src/components/navbars/navbar-vertical/NavbarVertical.tsx',
      'src/components/navbars/navbar-vertical/NavbarVerticalMenu.tsx',
      'src/components/navs/EcomCategoryNavs.tsx',
      'src/components/navs/IsotopeNav.tsx',
      'src/components/pricing-items/PricingColumnItem.tsx',
      'src/components/pricing-items/PricingGridItem.tsx',
      'src/components/progress-bars/DealForecasrProgressBar.tsx',
      'src/components/settings-panel/ChatWidgetVisibility.tsx',
      'src/components/settings-panel/ColorScheme.tsx',
      'src/components/settings-panel/HorizontalNavbarShape.tsx',
      'src/components/settings-panel/NavigationType.tsx',
      'src/components/settings-panel/RTLMode.tsx',
      'src/components/settings-panel/SettingsPanel.tsx',
      'src/components/settings-panel/SettingsToggle.tsx',
      'src/components/settings-panel/TopNavbarAppearance.tsx',
      'src/components/settings-panel/VerticalNavbarAppearance.tsx',
      'src/components/sliders/AlternateTestimonialCarousel.tsx',
      'src/components/sliders/SimilarProducts.tsx',
      'src/components/sliders/TestimonialCarousel.tsx',
      'src/components/stats/AnalyticsStats.tsx',
      'src/components/stats/EcomStats.tsx',
      'src/components/tables/CustomerOrdersTable.tsx',
      'src/components/tables/CustomerRatingsTable.tsx',
      'src/components/tables/CustomerWishlistTable.tsx',
      'src/components/tables/CustomersTable.tsx',
      'src/components/tables/DealDetailsCallTable.tsx',
      'src/components/tables/DealForecastTable.tsx',
      'src/components/tables/DealsReportTable.tsx',
      'src/components/tables/EcomAddressTable.tsx',
      'src/components/tables/EcomCartTable.tsx',
      'src/components/tables/EcomInvoiceTable.tsx',
      'src/components/tables/EcomLatestReviewsTable.tsx',
      'src/components/tables/EcomProfileOrdersTable.tsx',
      'src/components/tables/EcomProfileReviewsTable.tsx',
      'src/components/tables/EcomProfileStoresTable.tsx',
      'src/components/tables/EcomTopRegionsTable.tsx',
      'src/components/tables/EcomWishlistTable.tsx',
      'src/components/tables/LeadDealsTable.tsx',
      'src/components/tables/LeadEmailsTable.tsx',
      'src/components/tables/LeadsTable.tsx',
      'src/components/tables/MembersTable.tsx',
      'src/components/tables/OrderDetailsTable.tsx',
      'src/components/tables/OrdersTable.tsx',
      'src/components/tables/ProductsTable.tsx',
      'src/components/tables/ProjectDashboardTable.tsx',
      'src/components/tables/ProjectListTable.tsx',
      'src/components/tables/ReportDetailsTable.tsx',
      'src/components/tables/SpecificationTable.tsx',
      'src/components/tabs/InventoryTab.tsx',
      'src/components/timelines/ActivityTimeline.tsx',
      'src/components/timelines/BasicTimeline.tsx',
      'src/components/timelines/DealDetailsTimeline.tsx',
      'src/components/timelines/OrderTrackingTimeline.tsx',
      'src/components/wizard/WizardAccessDeniedModal.tsx',
      'src/components/wizard/WizardNav.tsx',
      'src/components/wizard/WizardSuccessStep.tsx',
      'src/config.ts',
      'src/data/changelog.ts',
      'src/data/crm/dashboardData.ts',
      'src/data/e-commerce/products.ts',
      'src/data/email.tsx',
      'src/data/faq.ts',
      'src/data/kanban.ts',
      'src/data/notifications.ts',
      'src/data/project-management/comments.tsx',
      'src/data/timelineData.ts',
      'src/helpers/echart-utils.ts',
      'src/helpers/utils.ts',
      'src/hooks/useAdvanceTable.tsx',
      'src/hooks/useToggleStyle.tsx',
      'src/layouts/AuthCardLayout.tsx',
      'src/layouts/DocPagesLayout.tsx',
      'src/layouts/EmailLayout.tsx',
      'src/pages/ComingSoon.tsx',
      'src/pages/Members.tsx',
      'src/pages/Showcase.tsx',
      'src/pages/apps/calendar/CalendarHeader.tsx',
      'src/pages/apps/calendar/CalendarTop.tsx',
      'src/pages/apps/crm/AddContact.tsx',
      'src/pages/apps/crm/Analytics.tsx',
      'src/pages/apps/crm/Deals.tsx',
      'src/pages/apps/crm/LeadDetails.tsx',
      'src/pages/apps/crm/Leads.tsx',
      'src/pages/apps/e-commerce/admin/AddProduct.tsx',
      'src/pages/apps/e-commerce/admin/CustomerDetails.tsx',
      'src/pages/apps/e-commerce/admin/Customers.tsx',
      'src/pages/apps/e-commerce/admin/OrderDetails.tsx',
      'src/pages/apps/e-commerce/admin/Orders.tsx',
      'src/pages/apps/e-commerce/admin/Products.tsx',
      'src/pages/apps/e-commerce/admin/Refund.tsx',
      'src/pages/apps/e-commerce/customer/FavoriteStores.tsx',
      'src/pages/apps/e-commerce/customer/Invoice.tsx',
      'src/pages/apps/e-commerce/customer/OrderTracking.tsx',
      'src/pages/apps/e-commerce/customer/ProductsFilter.tsx',
      'src/pages/apps/e-commerce/customer/Wishlist.tsx',
      'src/pages/apps/e-commerce/customer/checkout/ShippingInfo.tsx',
      'src/pages/apps/email/Compose.tsx',
      'src/pages/apps/email/EmailDetail.tsx',
      'src/pages/apps/events/CreateAnEvent.tsx',
      'src/pages/apps/events/EventDetail.tsx',
      'src/pages/apps/kanban/Boards.tsx',
      'src/pages/apps/kanban/CreateBoard.tsx',
      'src/pages/apps/kanban/Kanban.tsx',
      'src/pages/apps/project-management/ProjectBoardView.tsx',
      'src/pages/apps/project-management/ProjectCardView.tsx',
      'src/pages/apps/project-management/ProjectDetails.tsx',
      'src/pages/apps/project-management/ProjectListView.tsx',
      'src/pages/apps/project-management/ProjectTodoList.tsx',
      'src/pages/apps/social/Settings.tsx',
      'src/pages/apps/social/SocialProfile.tsx',
      'src/pages/dashboard/Crm.tsx',
      'src/pages/dashboard/ProjectManagement.tsx',
      'src/pages/dashboard/ecommerce/index.tsx',
      'src/pages/documentation/ChangeLog.tsx',
      'src/pages/documentation/Migrations.tsx',
      'src/pages/documentation/customization/Configuration.tsx',
      'src/pages/documentation/customization/DarkMode.tsx',
      'src/pages/documentation/customization/Styling.tsx',
      'src/pages/error/Error403.tsx',
      'src/pages/error/Error404.tsx',
      'src/pages/error/Error500.tsx',
      'src/pages/faq/FaqAccordion.tsx',
      'src/pages/faq/FaqTab.tsx',
      'src/pages/modules/charts/ECharts.tsx',
      'src/pages/modules/components/AccordionExample.tsx',
      'src/pages/modules/components/AlertsExample.tsx',
      'src/pages/modules/components/ButtonExample.tsx',
      'src/pages/modules/components/CountupExample.tsx',
      'src/pages/modules/components/NavbarExample.tsx',
      'src/pages/modules/components/TabsExample.tsx',
      'src/pages/modules/components/ToastsExample.tsx',
      'src/pages/modules/forms/WizardExample.tsx',
      'src/pages/modules/forms/advance/DatePickerExample.tsx',
      'src/pages/modules/tables/AdvanceTableExample.tsx',
      'src/pages/modules/utilities/BackgroundExample.tsx',
      'src/pages/modules/utilities/BorderExample.tsx',
      'src/pages/modules/utilities/ColorsExample.tsx',
      'src/pages/modules/utilities/FlexExample.tsx',
      'src/pages/modules/utilities/FloatExample.tsx',
      'src/pages/modules/utilities/GridExample.tsx',
      'src/pages/modules/utilities/InteractionsExample.tsx',
      'src/pages/modules/utilities/OpacityExample.tsx',
      'src/pages/modules/utilities/PositionExample.tsx',
      'src/pages/modules/utilities/ShadowsExample.tsx',
      'src/pages/modules/utilities/SizingExample.tsx',
      'src/pages/modules/utilities/SpacingExample.tsx',
      'src/pages/modules/utilities/StackExample.tsx',
      'src/pages/modules/utilities/TypographyExample.tsx',
      'src/pages/modules/utilities/VisibilityExample.tsx',
      'src/pages/notifications/Notifications.tsx',
      'src/pages/pages/Starter.tsx',
      'src/pages/pages/Timeline.tsx',
      'src/pages/pages/landing/Alternate.tsx',
      'src/pages/pages/landing/Default.tsx',
      'src/pages/pages/pricing/PricingGrid.tsx',
      'src/providers/AppProvider.tsx',
      'src/reducers/ConfigReducer.ts',
      'src/sitemap.tsx'
    ]
  },
  {
    from: 'v1.1.0',
    to: 'v1.2.0',
    new: [
      'src/assets/img/bg/bg-40.png',
      'src/assets/img/bg/bg-dark-40.png',
      'src/assets/img/kanban/1.jpg',
      'src/assets/img/kanban/a1.jpg',
      'src/assets/img/kanban/bg1.jpg',
      'src/assets/img/kanban/bg2.jpg',
      'src/assets/img/kanban/bg3.jpg',
      'src/assets/img/kanban/bg4.jpg',
      'src/assets/img/kanban/bg5.jpg',
      'src/assets/img/kanban/bg6.jpg',
      'src/assets/img/kanban/board-1.png',
      'src/assets/img/kanban/board-2.png',
      'src/assets/img/kanban/board-3.png',
      'src/assets/img/kanban/board-4.png',
      'src/assets/img/kanban/board-5.png',
      'src/assets/img/kanban/board-6.png',
      'src/assets/img/kanban/board-7.png',
      'src/assets/img/kanban/board-8.png',
      'src/assets/img/kanban/board-9.png',
      'src/assets/img/kanban/board-dark.png',
      'src/assets/img/kanban/board-light.png',
      'src/assets/img/kanban/board.png',
      'src/assets/img/kanban/create-board.png',
      'src/assets/img/kanban/glass.jpg',
      'src/assets/img/kanban/home.jpg',
      'src/assets/img/kanban/image-dark.png',
      'src/assets/img/kanban/image-light.png',
      'src/assets/img/kanban/modal-bg.jpg',
      'src/assets/img/kanban/wall.jpg',
      'src/assets/img/spot-illustrations/40.png',
      'src/assets/img/spot-illustrations/dark_40.png',
      'src/assets/scss/theme/_chat-widget.scss',
      'src/assets/scss/theme/_kanban.scss',
      'src/components/base/FullCalendar.tsx',
      'src/components/base/PhoenixDroppable.tsx',
      'src/components/base/PhoenixFloatingLabel.tsx',
      'src/components/base/ScrollSpy.tsx',
      'src/components/cards/DealCard.tsx',
      'src/components/cards/ProjectActivityCard.tsx',
      'src/components/common/AvatarDropzone.tsx',
      'src/components/common/chat-widget/ChatWidget.tsx',
      'src/components/common/chat-widget/ChatWidgetConversation.tsx',
      'src/components/common/chat-widget/ChatWidgetFooter.tsx',
      'src/components/common/chat-widget/MessageAttachments.tsx',
      'src/components/forms/WizardAccountForm.tsx',
      'src/components/forms/WizardBillingForm.tsx',
      'src/components/forms/WizardPersonalForm.tsx',
      'src/components/modals/CalendarAddNewEventModal.tsx',
      'src/components/modals/CalendarEventModal.tsx',
      'src/components/modals/DealsAddStageModal.tsx',
      'src/components/modals/FilterDealsModal.tsx',
      'src/components/modals/KanbanAddListModal.tsx',
      'src/components/modals/KanbanInviteModal.tsx',
      'src/components/modules/crm/deals/AddDealModal.tsx',
      'src/components/modules/crm/deals/DealColumn.tsx',
      'src/components/modules/e-commerce/dashboard/EcomProjectionVsActual.tsx',
      'src/components/modules/e-commerce/dashboard/EcomReturningCustomerRate.tsx',
      'src/components/modules/e-commerce/dashboard/EcomTopRegions.tsx',
      'src/components/modules/e-commerce/dashboard/EcomTotalSells.tsx',
      'src/components/modules/faq/CategoryOffcanvas.tsx',
      'src/components/modules/faq/CategoryTab.tsx',
      'src/components/modules/faq/FaqCategoryCard.tsx',
      'src/components/modules/faq/SubCategoryContent.tsx',
      'src/components/modules/faq/SubCategoryTab.tsx',
      'src/components/modules/kanban/**.*',
      'src/components/modules/landing/alternate/**.*',
      'src/components/modules/project-management/dashboard/ProjectDashboard.tsx',
      'src/components/modules/project-management/dashboard/ProjectElevenProgress.tsx',
      'src/components/modules/widgets/**.*',
      'src/components/settings-panel/ChatWidgetVisibility.tsx',
      'src/components/sliders/AlternateTestimonialCarousel.tsx',
      'src/components/timelines/BasicTimeline.tsx',
      'src/components/wizard/**.*',
      'src/data/calendarEvents.ts',
      'src/data/crm/deals.ts',
      'src/data/kanban.ts',
      'src/data/landing/alternate-landing-data.ts',
      'src/hooks/useFullCalendar.tsx',
      'src/hooks/usePhoenixForm.tsx',
      'src/hooks/useWizardForm.tsx',
      'src/pages/apps/calendar/**.*',
      'src/pages/apps/crm/Deals.tsx',
      'src/pages/apps/kanban/**.*',
      'src/pages/faq/FaqTab.tsx',
      'src/pages/modules/Widgets.tsx',
      'src/pages/modules/components/CalendarExample.tsx',
      'src/pages/modules/components/DraggableExample.tsx',
      'src/pages/modules/forms/WizardExample.tsx',
      'src/pages/pages/Timeline.tsx',
      'src/pages/pages/landing/Alternate.tsx',
      'src/providers/CalendarProvider.tsx',
      'src/providers/ChatWidgetProvider.tsx',
      'src/providers/CrmDealsProvider.tsx',
      'src/providers/FaqTabProvider.tsx',
      'src/providers/KanbanProvider.tsx',
      'src/providers/ScrollSpyProvider.tsx',
      'src/providers/WizardFormProvider.tsx',
      'src/reducers/CalendarReducer.ts',
      'src/reducers/KanbanReducer.ts'
    ],
    update: [
      'src/assets/scss/theme/_carousel.scss',
      'src/assets/scss/theme/_chat.scss',
      'src/assets/scss/theme/_crm.scss',
      'src/assets/scss/theme/_ecommerce.scss',
      'src/assets/scss/theme/_forms.scss',
      'src/assets/scss/theme/_landing.scss',
      'src/assets/scss/theme/_mixed.scss',
      'src/assets/scss/theme/_nav-tab.scss',
      'src/assets/scss/theme/_navbar-vertical.scss',
      'src/assets/scss/theme/_offcanvas.scss',
      'src/assets/scss/theme/_progress.scss',
      'src/assets/scss/theme/_scrollbar.scss',
      'src/assets/scss/theme/_theme.scss',
      'src/assets/scss/theme/_timeline.scss',
      'src/assets/scss/theme/_utilities.scss',
      'src/assets/scss/theme/_variables.scss',
      'src/assets/scss/theme/helpers/_background.scss',
      'src/assets/scss/theme/plugins/_full-calendar.scss',
      'src/assets/scss/theme/plugins/_isotope.scss',
      'src/assets/scss/theme/plugins/_react-dropzone.scss',
      'src/assets/scss/theme/plugins/_swiper.scss',
      'src/assets/scss/theme/plugins/_tinymce.scss',
      'src/assets/scss/theme/root/_dark.scss',
      'src/components/base/Avatar.tsx',
      'src/components/base/Dropzone.tsx',
      'src/components/base/Swiper.tsx',
      'src/components/base/Timeline.tsx',
      'src/components/cards/EventTitleCard.tsx',
      'src/components/cards/SocialProfileCard.tsx',
      'src/components/charts/e-charts/AnalyticsEmailCampaignChart.tsx',
      'src/components/common/AttachmentPreview.tsx',
      'src/components/common/CoverUpload.tsx',
      'src/components/cta/FaqCta.tsx',
      'src/components/forms/EventTicketPricing.tsx',
      'src/components/forms/FeedTextarea.tsx',
      'src/components/modals/ReportFilterModal.tsx',
      'src/components/modules/chat/UserList.tsx',
      'src/components/modules/chat/chat-content/ChatContentFooter.tsx',
      'src/components/modules/chat/chat-content/index.tsx',
      'src/components/modules/chat/message/MessageAttachments.tsx',
      'src/components/modules/chat/message/index.tsx',
      'src/components/modules/crm/LeadAttachments.tsx',
      'src/components/modules/crm/LeadDeals.tsx',
      'src/components/modules/crm/LeadDetailsNavbar.tsx',
      'src/components/modules/crm/LeadEmails.tsx',
      'src/components/modules/crm/Tasks.tsx',
      'src/components/modules/e-commerce/ProductGallery.tsx',
      'src/components/modules/landing/default/Brands.tsx',
      'src/components/modules/project-management/dashboard/ZeroRoadMap.tsx',
      'src/components/navbars/default-landing-navbar/DefaultLandingNavbar.tsx',
      'src/components/navbars/navbar-vertical/NavbarVerticalMenu.tsx',
      'src/components/settings-panel/SettingsPanel.tsx',
      'src/data/changelog.ts',
      'src/data/chat.ts',
      'src/data/commonData.ts',
      'src/data/faq.ts',
      'src/data/landing/pricingData.ts',
      'src/data/landing/testimonial.ts',
      'src/data/timelineData.ts',
      'src/helpers/utils.ts',
      'src/hooks/useToggleStyle.tsx',
      'src/index.tsx',
      'src/layouts/EcommerceLayout.tsx',
      'src/layouts/MainLayout.tsx',
      'src/pages/ComingSoon.tsx',
      'src/pages/apps/chat/ChatConversation.tsx',
      'src/pages/apps/crm/DealDetails.tsx',
      'src/pages/apps/crm/LeadDetails.tsx',
      'src/pages/apps/email/EmailDetail.tsx',
      'src/pages/apps/events/EventDetail.tsx',
      'src/pages/apps/social/Feed.tsx',
      'src/pages/apps/social/Settings.tsx',
      'src/pages/dashboard/ProjectManagement.tsx',
      'src/pages/dashboard/ecommerce/index.tsx',
      'src/pages/documentation/ChangeLog.tsx',
      'src/pages/documentation/customization/Configuration.tsx',
      'src/pages/faq/FaqAccordion.tsx',
      'src/pages/modules/components/CountupExample.tsx',
      'src/pages/modules/components/SwiperCarousel.tsx',
      'src/pages/pages/landing/Default.tsx',
      'src/providers/AppProvider.tsx',
      'src/providers/BreakpointsProvider.tsx',
      'src/providers/ChatProvider.tsx',
      'src/reducers/ChatReducer.ts',
      'src/reducers/ConfigReducer.ts',
      'src/config.ts',
      'src/sitemap.tsx',
      'src/Routes.tsx',
      'types/react-bootstrap.d.ts',
      'webpack.config.cjs',
      'package-lock.json',
      'package.json'
    ]
  },
  {
    from: 'v1.0.0',
    to: 'v1.1.0',
    new: [
      'src/assets/img/bg/10-dark.png',
      'src/assets/img/bg/40.png',
      'src/assets/img/bg/8-dark.png',
      'src/assets/img/bg/9-dark.png',
      'src/assets/img/bg/bg-11-dark.png',
      'src/assets/img/spot-illustrations/bag-2-dark.png',
      'src/assets/img/spot-illustrations/rocket-dark.png',
      'src/assets/img/spot-illustrations/shield-2-dark.png',
      'src/assets/img/spot-illustrations/star-dark.png',
      'src/components/base/Unicon.tsx',
      'src/components/cards/AboutLeadCard.tsx',
      'src/components/cards/CrmStatCard.tsx',
      'src/components/cards/DealProfileCard.tsx',
      'src/components/cards/DealsPrintingDimensionsCard.tsx',
      'src/components/cards/IconCard.tsx',
      'src/components/cards/LeadAddressCard.tsx',
      'src/components/cards/LeadProfileCard.tsx',
      'src/components/cards/MeetingCard.tsx',
      'src/components/cards/ReportCard.tsx',
      'src/components/charts/e-charts/AdClicksChart.tsx',
      'src/components/charts/e-charts/AnalyticsCallCampaignChart.tsx',
      'src/components/charts/e-charts/AnalyticsEmailCampaignChart.tsx',
      'src/components/charts/e-charts/AnalyticsSalesTrendsChart.tsx',
      'src/components/charts/e-charts/BasicLineChart.tsx',
      'src/components/charts/e-charts/ContactsBySourceChart.tsx',
      'src/components/charts/e-charts/ContactsCreatedChart.tsx',
      'src/components/charts/e-charts/LeadConversationChart.tsx',
      'src/components/charts/e-charts/MarketingCampaignChart.tsx',
      'src/components/charts/e-charts/ReportDetailsChart.tsx',
      'src/components/charts/e-charts/RevenueTargetChart.tsx',
      'src/components/common/PhoenixLoader.tsx',
      'src/components/forms/AddressInformation.tsx',
      'src/components/forms/DealsOtherInformation.tsx',
      'src/components/forms/LeadInformationForm.tsx',
      'src/components/grid-list-items/ContactSourceItem.tsx',
      'src/components/icons/CircleProgress.tsx',
      'src/components/info-items/LeadInfoItem.tsx',
      'src/components/list-items/LeadSources.tsx',
      'src/components/list-items/PricingPackageList.tsx',
      'src/components/modals/LeadsFilterModal.tsx',
      'src/components/modals/ReportFilterModal.tsx',
      'src/components/modals/ReportsFilterModal.tsx',
      'src/components/modules/auth/*.tsx',
      'src/components/modules/crm/**/*.tsx',
      'src/components/pricing-items/PricingGridItem.tsx',
      'src/components/progress-bars/DealForecasrProgressBar.tsx',
      'src/components/stats/AnalyticsStats.tsx',
      'src/components/tables/DealDetailsCallTable.tsx',
      'src/components/tables/DealForecastTable.tsx',
      'src/components/tables/DealsReportTable.tsx',
      'src/components/tables/LeadDealsTable.tsx',
      'src/components/tables/LeadEmailsTable.tsx',
      'src/components/tables/LeadsTable.tsx',
      'src/components/tables/ReportDetailsTable.tsx',
      'src/components/timelines/DealDetailsTimeline.tsx',
      'src/layouts/AuthCardLayout.tsx',
      'src/layouts/AuthSimpleLayout.tsx',
      'src/layouts/AuthSplitLayout.tsx',
      'src/pages/apps/crm/*.tsx',
      'src/pages/dashboard/Crm.tsx',
      'src/pages/modules/components/FontAwesomeExample.tsx',
      'src/pages/modules/components/UniconsExample.tsx',
      'src/pages/pages/authentication/card/*.tsx',
      'src/pages/pages/authentication/split/*.tsx',
      'src/pages/pages/pricing/PricingGrid.tsx',
      'src/pages/documentation/Migrations.tsx',
      'src/data/crm/*.ts',
      'src/data/icons/*.ts',
      'src/data/changelog.ts',
      'src/data/migration.ts'
    ],
    update: [
      '.eslintignore',
      '.eslintrc.json',
      'package.json',
      'src/Routes.tsx',
      'src/assets/scss/theme/_crm.scss',
      'src/assets/scss/theme/_mixed.scss',
      'src/assets/scss/theme/_offcanvas.scss',
      'src/assets/scss/theme/_pricing.scss',
      'src/assets/scss/theme/_progress.scss',
      'src/assets/scss/theme/_table.scss',
      'src/assets/scss/theme/_theme.scss',
      'src/assets/scss/theme/_variables.scss',
      'src/assets/scss/theme/helpers/_borders.scss',
      'src/components/base/AdvanceTable.tsx',
      'src/components/base/AdvanceTableFooter.tsx',
      'src/components/base/Dropzone.tsx',
      'src/components/base/MapBox.tsx',
      'src/components/base/PhoenixDocCard.tsx',
      'src/components/base/Rating.tsx',
      'src/components/base/ReactSelect.tsx',
      'src/components/base/RevealDropdown.tsx',
      'src/components/base/Swiper.tsx',
      'src/components/cards/CustomerProfileCard.tsx',
      'src/components/cards/EarlyBirdCard.tsx',
      'src/components/cards/EcoimDefaultAddressCard.tsx',
      'src/components/cards/EcomCartSummaryCard.tsx',
      'src/components/cards/EcomProfileCard.tsx',
      'src/components/cards/EventTitleCard.tsx',
      'src/components/cards/SocialCoverCard.tsx',
      'src/components/cards/SocialProfileCard.tsx',
      'src/components/cards/UsuallyBoughtTogetherCard.tsx',
      'src/components/charts/e-charts/EcomProjectionVsActualChart.tsx',
      'src/components/charts/e-charts/ProjectElevenProgressChart.tsx',
      'src/components/common/AttachmentPreview.tsx',
      'src/components/common/AuthSocialButtons.tsx',
      'src/components/common/AvatarDropdown.tsx',
      'src/components/common/AvatarUpload.tsx',
      'src/components/common/CommentForm.tsx',
      'src/components/common/CoverUpload.tsx',
      'src/components/common/DropdownSearchBox.tsx',
      'src/components/common/EditableDetailsField.tsx',
      'src/components/common/FilterButtonGroup.tsx',
      'src/components/common/FormCollapse.tsx',
      'src/components/common/ImageAttachmentPreview.tsx',
      'src/components/common/NotificationItem.tsx',
      'src/components/common/ProductCard.tsx',
      'src/components/common/QuantityButtons.tsx',
      'src/components/common/SearchBox.tsx',
      'src/components/common/SearchResult.tsx',
      'src/components/common/StoreItem.tsx',
      'src/components/common/WarningMessage.tsx',
      'src/components/cta/EcomBecomeMember.tsx',
      'src/components/cta/FaqCta.tsx',
      'src/components/footers/EcommerceFooter.tsx',
      'src/components/forms/EventCustomFields.tsx',
      'src/components/forms/EventTicketPricing.tsx',
      'src/components/forms/FeedTextarea.tsx',
      'src/components/forms/SettingsChangePassword.tsx',
      'src/components/forms/SettingsCompanyInfo.tsx',
      'src/components/forms/SettingsPersonalInfo.tsx',
      'src/components/forms/SettingsSocial.tsx',
      'src/components/list-items/EventItem.tsx',
      'src/components/list-items/ProductReview.tsx',
      'src/components/list-items/ProfileNavigation.tsx',
      'src/components/list-items/SocialMessages.tsx',
      'src/components/modules/chat/ChatSidebar.tsx',
      'src/components/modules/chat/chat-content/ChatContentFooter.tsx',
      'src/components/modules/chat/chat-content/ChatContentHeader.tsx',
      'src/components/modules/chat/conversation-details/SharedFiles.tsx',
      'src/components/modules/chat/conversation-details/SharedMedia.tsx',
      'src/components/modules/chat/conversation-details/index.tsx',
      'src/components/modules/e-commerce/OrderDeliveryDetails.tsx',
      'src/components/modules/e-commerce/ProductDescription.tsx',
      'src/components/modules/e-commerce/ProductDetailsTab.tsx',
      'src/components/modules/e-commerce/profile/ProfileDetailsTab.tsx',
      'src/components/modules/email/EmailRow.tsx',
      'src/components/modules/email/EmailSidebar.tsx',
      'src/components/modules/email/InboxToolbar.tsx',
      'src/components/modules/events/EventsOrganized.tsx',
      'src/components/modules/events/Location.tsx',
      'src/components/modules/events/ResponsesAndShare.tsx',
      'src/components/modules/landing/default/Cta.tsx',
      'src/components/modules/landing/default/HeroHeader.tsx',
      'src/components/modules/landing/default/address/HelpCenter.tsx',
      'src/components/modules/landing/default/blog/Blog.tsx',
      'src/components/modules/landing/default/blog/BlogItem.tsx',
      'src/components/modules/landing/default/features/FeatureSection.tsx',
      'src/components/modules/landing/default/features/Features.tsx',
      'src/components/modules/project-management/ProjectsTopSection.tsx',
      'src/components/modules/project-management/board-view/ActionSection.tsx',
      'src/components/modules/project-management/board-view/BoardViewModal.tsx',
      'src/components/modules/project-management/board-view/CoverImage.tsx',
      'src/components/modules/project-management/card-view/CardViewItem.tsx',
      'src/components/modules/project-management/card-view/CardViewModal.tsx',
      'src/components/modules/project-management/dashboard/Stat.tsx',
      'src/components/modules/project-management/project-details/ProjectDetailsSummary.tsx',
      'src/components/modules/project-management/todo-list/FileListItem.tsx',
      'src/components/modules/project-management/todo-list/SubTask.tsx',
      'src/components/modules/project-management/todo-list/TodoItemDetailsModal.tsx',
      'src/components/modules/project-management/todo-list/TodoItemDetailsOffcanvas.tsx',
      'src/components/modules/project-management/todo-list/TodoList.tsx',
      'src/components/modules/project-management/todo-list/TodoListItem.tsx',
      'src/components/modules/social/NavbarBottom.tsx',
      'src/components/modules/social/PostCard.tsx',
      'src/components/modules/social/PostComments.tsx',
      'src/components/navbars/ecommerce/EcommerceNavbar.tsx',
      'src/components/navbars/nav-items/NavItemsSlim.tsx',
      'src/components/navbars/navbar-horizontal/NavbarTopNav.tsx',
      'src/components/navbars/navbar-vertical/NavbarVerticalMenu.tsx',
      'src/components/pricing-items/PricingColumnItem.tsx',
      'src/components/settings-panel/SettingsPanel.tsx',
      'src/components/sliders/EcomBestOffers.tsx',
      'src/components/sliders/EcomTopDeals.tsx',
      'src/components/sliders/EcomTopElectronics.tsx',
      'src/components/stats/EcomStats.tsx',
      'src/components/tables/EcomCartTable.tsx',
      'src/components/tables/EcomLatestReviewsTable.tsx',
      'src/components/tables/EcomWishlistTable.tsx',
      'src/components/tables/ProjectDashboardTable.tsx',
      'src/components/tabs/InventoryTab.tsx',
      'src/components/timelines/OrderTrackingTimeline.tsx',
      'src/data/chat.ts',
      'src/data/email.tsx',
      'src/data/landing/pricingData.ts',
      'src/data/pricing.ts',
      'src/data/project-management/actions.ts',
      'src/data/project-management/activityTimelineData.ts',
      'src/data/project-management/comments.tsx',
      'src/data/project-management/todoListData.ts',
      'src/data/search.ts',
      'src/data/social/dropdownData.ts',
      'src/data/timelineData.ts',
      'src/helpers/echart-utils.ts',
      'src/helpers/utils.ts',
      'src/hooks/useAdvanceTable.tsx',
      'src/index.tsx',
      'src/layouts/EmailLayout.tsx',
      'src/pages/ComingSoon.tsx',
      'src/pages/Members.tsx',
      'src/pages/apps/crm/Leads.tsx',
      'src/pages/apps/e-commerce/admin/CustomerDetails.tsx',
      'src/pages/apps/e-commerce/admin/Customers.tsx',
      'src/pages/apps/e-commerce/admin/OrderDetails.tsx',
      'src/pages/apps/e-commerce/admin/Orders.tsx',
      'src/pages/apps/e-commerce/admin/Products.tsx',
      'src/pages/apps/e-commerce/admin/Refund.tsx',
      'src/pages/apps/e-commerce/customer/Invoice.tsx',
      'src/pages/apps/e-commerce/customer/OrderTracking.tsx',
      'src/pages/apps/e-commerce/customer/ProductsFilter.tsx',
      'src/pages/apps/e-commerce/customer/Profile.tsx',
      'src/pages/apps/email/Compose.tsx',
      'src/pages/apps/email/EmailDetail.tsx',
      'src/pages/apps/project-management/ProjectBoardView.tsx',
      'src/pages/apps/project-management/ProjectCardView.tsx',
      'src/pages/apps/project-management/ProjectDetails.tsx',
      'src/pages/apps/project-management/ProjectListView.tsx',
      'src/pages/apps/project-management/ProjectTodoList.tsx',
      'src/pages/modules/components/BootstrapCarousel.tsx',
      'src/pages/modules/components/ButtonExample.tsx',
      'src/pages/modules/components/FeatherIconsExample.tsx',
      'src/pages/modules/components/PaginationExample.tsx',
      'src/pages/modules/forms/advance/EmojiButtonExample.tsx',
      'src/pages/pages/Starter.tsx',
      'src/pages/pages/authentication/simple/ForgotPassword.tsx',
      'src/pages/pages/authentication/simple/LockScreen.tsx',
      'src/pages/pages/authentication/simple/ResetPassword.tsx',
      'src/pages/pages/authentication/simple/SignIn.tsx',
      'src/pages/pages/authentication/simple/SignOut.tsx',
      'src/pages/pages/authentication/simple/SignUp.tsx',
      'src/pages/pages/authentication/simple/TwoFA.tsx',
      'src/pages/pages/pricing/PricingColumn.tsx',
      'src/providers/PhoenixDocProvider.tsx',
      'src/sitemap.tsx',
      'types/phoenix-module.d.ts',
      'types/react-table.d.ts',
      'src/pages/documentation/ChangeLog.tsx'
    ],
    delete: [
      'src/helpers/initFA.ts',
      'src/layouts/SimpleAuthLayout.tsx',
      'src/pages/modules/components/FontAwesome.tsx',
      'src/pages/modules/components/Unicons.tsx'
    ]
  }
];
